import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nidhi-company',
  templateUrl: './nidhi-company.component.html',
  styleUrls: ['./nidhi-company.component.css']
})
export class NidhiCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
