import { Injectable } from '@angular/core';
import {Observable , observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import  { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { packagePost } from './class/heading';

@Injectable({
  providedIn: 'root'
})
export class ApiRoutesService {

  constructor(private httpclient:HttpClient , private router :Router) { }


  public baseUrl = "https://gst.myndtax.com/api/";


  // signup customer
  postClientData(opost:packagePost):Observable<InstanceType<any>>
  {
    return this.httpclient.post(this.baseUrl+'create-ticket',opost);
  }

  getOrderId(amount:any){
    return this.httpclient.get(this.baseUrl+'orderid/'+amount);
  }

  paymentDone(payload:any){
    return this.httpclient.post(this.baseUrl+'paymentDone',payload);
  }
}



