<div class="w-100  menu-header">
  <div class="w-100 max-width mx-auto d-none d-md-flex">
    <div class="w-25">
      <a routerLink="/"><img src="assets/img/myntax-transparent.png" class="mt-1 menu-logo" height="40px" /></a>
    </div>
    <div class="w-65">
      <ul class="m-0" style="position:relative">
        <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a class="hover-tertiary-myntax hover-show menu-header-tab text-white">Startup</a>
          <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000;width:640px">
            <div class="row">
              <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a class="text-dark text-decoration-none font-weight-bold">Popular</a>
                  </li>
                  <li><a routerLink="/startup-proprietorship"
                      class="text-dark text-decoration-none hover-bold">Proprietorship</a>
                  </li>
                  <li><a routerLink="/startup-partnership"
                      class="text-dark text-decoration-none hover-bold">Partnership</a>
                  </li>
                  <li><a routerLink="/startup-one-person-company" class="text-dark text-decoration-none hover-bold">One
                      Person
                      Company</a></li>
                  <li><a routerLink="/startup-limited-liability-partnership"
                      class="text-dark text-decoration-none hover-bold">Limited
                      Liability
                      Partnership</a></li>
                  <li><a routerLink="/startup-private-limited-company"
                      class="text-dark text-decoration-none hover-bold">Private
                      limited
                      Company</a></li>
                </ul>
              </div>
              <!-- <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a routerLink="" class="text-dark text-decoration-none font-weight-bold">Public
                      Company</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Public Limited
                      Company</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Nidhi Company</a>
                  </li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Producer
                      Company</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Section 8
                      company</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">MSME
                      registration</a></li>
                </ul>
              </div> -->
              <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a class="text-dark text-decoration-none font-weight-bold">Other
                      Registrations</a></li>
                  <li><a routerLink="/startup-digital-signature"
                      class="text-dark text-decoration-none hover-bold">Digital
                      Signature</a></li>
                  <li><a routerLink="/startup-udyam-registration"
                      class="text-dark text-decoration-none hover-bold">Udyam Registration</a>
                  </li>
                  <li><a routerLink="/startup-import-export-code"
                      class="text-dark text-decoration-none hover-bold">Import
                      Export
                      Code</a></li>
                  <li><a routerLink="/startup-fssai-registration"
                      class="text-dark text-decoration-none hover-bold">FSSAI
                      registration</a></li>

                </ul>
              </div>
            </div>
          </div>
        </li>
        <!-- <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Intellectual
            Property</a>
          <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000; width:500px">
            <div class="row">
              <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a routerLink="" class="text-dark text-decoration-none font-weight-bold">Popular</a>
                  </li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Trademark
                      Registration</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Trademark
                      Objection</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Trademark
                      Opposition</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Trademark
                      Renewal</a></li>
                </ul>
              </div>
              <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a routerLink="" class="text-dark text-decoration-none font-weight-bold">OTHER</a>
                  </li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Copyright
                      Registration</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Design
                      Registration</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Provisional
                      Patent</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Patent
                      Registration</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li> -->
        <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a class="hover-tertiary-myntax hover-show menu-header-tab text-white">GST</a>
          <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000;width:300px">
            <div class="row">
              <div class="col-lg-12 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a class="text-dark text-decoration-none font-weight-bold">GST SERVICE</a></li>
                  <li><a routerLink="/gst-new-registration" class="text-dark text-decoration-none hover-bold">GST
                      Registration</a></li>
                  <li><a routerLink="/gst-return-filling" class="text-dark text-decoration-none hover-bold">GST Return
                      Filing</a>
                  </li>
                  <li><a routerLink="/gst-annual-return" class="text-dark text-decoration-none hover-bold">GST Annual
                      Return
                    </a></li>
                  <li><a routerLink="/gst-lut-filling" class="text-dark text-decoration-none hover-bold">GST LUT
                      filling</a></li>
                  <li><a routerLink="/temporary-gst-number" class="text-dark text-decoration-none hover-bold">Temporary
                      GST
                      number</a></li>
                </ul>
              </div>
              <!-- <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a routerLink="" class="text-dark text-decoration-none font-weight-bold">GST
                      SERVICE</a></li>
                  <li><a routerLink="gst-filling" class="text-dark text-decoration-none hover-bold">GST
                      Return Filling</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">e way Bill</a>
                  </li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Input Tax
                      Credit</a></li>
                </ul>
              </div> -->
            </div>
          </div>
        </li>
        <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a class="hover-tertiary-myntax hover-show menu-header-tab text-white">Income Tax</a>
          <div class="dropdown-l bg-white box-shadow-1 transition-1" style="z-index: 1000;width:300px">
            <div class="row">
              <div class="col-lg-12 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a class="text-dark text-decoration-none font-weight-bold">Annual
                      Compliance </a></li>
                  <li><a routerLink="/incometax-proprietorship-it"
                      class="text-dark text-decoration-none hover-bold">Proprietorship
                      IT</a></li>
                  <li><a routerLink="/incometax-privatelimitedcompany"
                      class="text-dark text-decoration-none hover-bold">Private
                      Limited
                      company IT</a></li>
                  <li><a routerLink="incometax-limitedliabilitypartnership"
                      class="text-dark text-decoration-none hover-bold">LLP
                      IT</a></li>
                  <li><a routerLink="/incometax-itr-filing" class="text-dark text-decoration-none hover-bold">ITR
                      Filling</a>
                  </li>
                  <li><a routerLink="/incometax-incometaxnotice"
                      class="text-dark text-decoration-none hover-bold">Income Tax
                      notice</a></li>
                  <li><a routerLink="/incometax-tdsreturnfiling" class="text-dark text-decoration-none hover-bold">TDS
                      Return
                      Filling</a></li>
                  <li><a routerLink="/incometax-form16issuance" class="text-dark text-decoration-none hover-bold">Form
                      16
                      Issuance</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a routerLink="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Compliance</a>
          <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000; width:300px">
            <div class="row">
              <!-- <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a routerLink="" class="text-dark text-decoration-none font-weight-bold">Payroll </a>
                  </li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">Payroll</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">PF
                      Registration</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">PF Return
                      Filling</a></li>
                  <li><a routerLink="#" class="text-dark text-decoration-none hover-bold">ESI
                      Registration</a></li>
                </ul>
              </div> -->
              <div class=" p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a class="text-dark text-decoration-none font-weight-bold">Corporate Fillings </a>
                  </li>
                  <li><a routerLink="/registration-office-change"
                      class="text-dark text-decoration-none hover-bold">Registered
                      Office
                      Change</a></li>
                  <li><a routerLink="/add-directors" class="text-dark text-decoration-none hover-bold">Add Directors</a>
                  </li>
                  <li><a routerLink="/remove-directors" class="text-dark text-decoration-none hover-bold">Remove
                      Directors</a></li>
                  <li><a routerLink="/increase-authorized-capital"
                      class="text-dark text-decoration-none hover-bold">Increased
                      Authorized Capital</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a routerLink="/about-us" class="hover-tertiary-myntax hover-show menu-header-tab text-white">About Us</a>
        </li>
        <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a routerLink="/contact-us" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Contact Us</a>
        </li>

        <!-- <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
          <a class="hover-tertiary-myntax hover-show menu-header-tab text-white">More</a>
          <div class="dropdown-l bg-white box-shadow-1 transition-1" style="z-index: 1000;width:250px">
            <div class="row">
              <div class="col-lg-12 p-2 box-sizing-border-box menu-data-list">
                <ul>
                  <li><a routerLink="/about-us" class="text-dark text-decoration-none  hover-bold">About Us</a>
                  </li>
                  <li><a routerLink="/contact-us" class="text-dark text-decoration-none hover-bold">Contact Us</a>
                  </li>
                  <li><a routerLink="/documents" class="text-dark text-decoration-none hover-bold">Documents</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li> -->
      </ul>
    </div>

  </div>
  <div class="w-100 mx-auto bg-myntax py-2 display-flex d-md-none">
    <div class="w-50">
      <div class="w-100 px-2">
        <a routerLink="/"><img src="../../assets/img/myntax-transparent.png" class="mt-1 menu-logo" height="30px" /></a>
      </div>
    </div>
    <div class="w-50 pr-3 box-sizing-border-box pt-2 text-right">
      <i class=" text-white fa fa-bars" (click)="menuShow()" style="font-size:25px;"></i>
    </div>
  </div>
</div>
<div class="w-100 position-fixed d-none  mobile-show mobileChecking"
  style="background-color:rgba(0,0,0,0.4);height: 100vh;top:0px;z-index: 100000;">
  <div class="display-flex">
    <div class="w-25" (click)="crossSide()">
    </div>
    <div class="w-75">
      <div class="w-100 bg-white p-3 box-sizing-border-box h-100" style="min-height: 100vh !important;">
        <div class="w-100">
          <!--img-->
          <div class="w-100">
            <img src="../../assets/img/myntax.png" alt="myntax logo" style="height:30px;">
            <i class="fa fa-times text-dark font-weight-bold float-right position-relative cross" (click)="crossSide()"
              style="font-size:18px;top:8px"></i>
          </div>
        </div>
        <div class="w-100 border-top-1 py-3 mt-4">
          <div><a routerLink="/" class="text-dark px-3  p-1 home-pills">Home</a></div>
        </div>
        <div class="w-100 border-top-1 py-2">
          <div (click)="down()" class="parent">
            <a class="text-dark px-3 font-weight-bold p-1">Startup</a>
            <i class="fa fa-sort-desc float-right font-weight-bold"></i>
          </div>
          <div class="outerDownShow d-none">
            <div class="pl-3 mt-2 border-top-1">
              <div class="py-2"><a routerLink="/startup-proprietorship"
                  class="text-dark px-3 p-2 startup-proprietorship">Proprietorship</a></div>
              <div class="py-2 border-top-1"><a routerLink="/startup-partnership"
                  class="text-dark px-3 p-2 startup-proprietorship">Partnership</a></div>
              <div class="py-2 border-top-1"><a routerLink="/startup-limited-liability-partnership" class=" text-dark
                  px-3 p-2 startup-proprietorship">Limited
                  liability partnership</a>
              </div>
              <div class="py-2 border-top-1"><a routerLink="/startup-private-limited-company"
                  class="text-dark px-3 p-2 startup-proprietorship">Private
                  limited company</a></div>


              <div class="py-2 border-top-1"><a routerLink="/startup-fssai-registration"
                  class="text-dark px-3 p-2 startup-proprietorship">Fssai
                  Registration</a></div>
              <div class="py-2 border-top-1"><a routerLink="/startup-digital-signature"
                  class="text-dark px-3 p-2 startup-proprietorship">Digital
                  Signature</a></div>
              <div class="py-2 border-top-1"><a routerLink="/startup-udyam-registration"
                  class="text-dark px-3 p-2 startup-proprietorship">Udyam Registration</a></div>
              <div class="py-2 border-top-1"><a routerLink="/startup-digital-signature"
                  class="text-dark px-3 p-2 startup-proprietorship">Digital
                  Signature</a></div>
              <div class="py-2 border-top-1"><a routerLink="/startup-import-export-code"
                  class="text-dark px-3 p-2 startup-proprietorship">Import Export Code</a></div>
            </div>
          </div>
          <br>
          <div class="" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false"
            aria-controls="collapseWidthExample">
            <a class="text-dark px-3 font-weight-bold p-1">GST</a>
            <i class="fa fa-sort-desc float-right font-weight-bold"></i>
          </div>
          <div class="outerDownShow collapse" id="collapse2">
            <div class="pl-3 mt-2 border-top-1">
              <div class="py-2"><a routerLink="/gst-new-registration"
                  class="text-dark px-3 p-2 startup-proprietorship">GST
                  Registration</a></div>

              <div class="py-2 border-top-1"><a routerLink="/gst-annual-return"
                  class="text-dark px-3 p-2 startup-proprietorship">GST
                  Annual
                  Return</a></div>
              <div class="py-2 border-top-1"><a routerLink="/gst-lut-filling"
                  class="text-dark px-3 p-2 startup-proprietorship">GST LUT
                  filling</a></div>
              <div class="py-2 border-top-1"><a routerLink="/temporary-gst-number"
                  class="text-dark px-3 p-2 startup-proprietorship">Temporary GST
                  number</a></div>
              <div class="py-2 border-top-1"><a routerLink="/gst-return-filling"
                  class="text-dark px-3 p-2 startup-proprietorship">GST
                  Return
                  Filling</a></div>


            </div>
          </div>

          <br>
          <div class="" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false"
            aria-controls="collapseWidthExample">
            <a class="text-dark px-3 font-weight-bold p-1">Income Tax</a>
            <i class="fa fa-sort-desc float-right font-weight-bold"></i>
          </div>
          <div class="outerDownShow collapse" id="collapse3">
            <div class="pl-3 mt-2 border-top-1">
              <div class="py-2"><a routerLink="/incometax-proprietorship-it"
                  class="text-dark px-3 p-2 startup-proprietorship">Proprietorship IT</a>
              </div>
              <div class="py-2 border-top-1"><a routerLink="/incometax-privatelimitedcompany"
                  class="text-dark px-3 p-2 startup-proprietorship">Private
                  Limited company IT</a>
              </div>
              <div class="py-2 border-top-1"><a routerLink="/incometax-limitedliabilitypartnership"
                  class="text-dark px-3 p-2 startup-proprietorship">LLP
                  IT</a>
              </div>
              <div class="py-2 border-top-1"><a routerLink="/incometax-itr-filing"
                  class="text-dark px-3 p-2 startup-proprietorship">ITR Filling</a></div>
              <div class="py-2 border-top-1"><a routerLink="/incometax-incometaxnotice"
                  class="text-dark px-3 p-2 startup-proprietorship">Income
                  Tax
                  notice</a></div>
              <div class="py-2 border-top-1"><a routerLink="/incometax-tdsreturnfiling"
                  class="text-dark px-3 p-2 startup-proprietorship">TDS
                  Return
                  Filling</a></div>
              <div class="py-2 border-top-1"><a routerLink="/incometax-form16issuance"
                  class="text-dark px-3 p-2 startup-proprietorship">Form 16
                  Issuance</a></div>
            </div>
          </div>

          <br>
          <div class="" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false"
            aria-controls="collapseWidthExample">
            <a class="text-dark px-3 font-weight-bold p-1">Compliance</a>
            <i class="fa fa-sort-desc float-right font-weight-bold"></i>
          </div>
          <div class="outerDownShow collapse" id="collapse4">
            <div class="pl-3 mt-2 border-top-1">
              <div class="py-2 border-top-1"><a routerLink="/registration-office-change"
                  class="text-dark px-3 p-2 startup-proprietorship">Registered
                  Office Change</a></div>
              <div class="py-2 border-top-1"><a routerLink="/add-directors"
                  class="text-dark px-3 p-2 startup-proprietorship">Add
                  Directors</a></div>
              <div class="py-2 border-top-1"><a routerLink="/remove-directors"
                  class="text-dark px-3 p-2 startup-proprietorship">Remove
                  Directors</a></div>
              <div class="py-2 border-top-1"><a routerLink="/increase-authorized-capital"
                  class="text-dark px-3 p-2 startup-proprietorship">Increased Authorized Capital</a></div>
            </div>
          </div>

          <div class="py-2  mt-4">
            <a routerLink="/about-us" class="text-dark px-3 p-2 startup-proprietorship">About Us</a>
          </div>
          <div class="py-2  mt-2">
            <a routerLink="/contact-us" class="text-dark px-3 p-2 startup-proprietorship">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
