import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-colored-menu',
  templateUrl: './colored-menu.component.html',
  styleUrls: ['./colored-menu.component.css']
})
export class ColoredMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  menuShow(){
    var display=<HTMLElement>document.querySelector(".mobileChecking");
    if(display.classList.contains('mobile-show') == true)
    {
      display.classList.remove('d-none');
    }
  }

  crossSide()
  {
    var display=<HTMLElement>document.querySelector(".mobileChecking");

    if(display.classList.contains('mobile-show') == true)
    {
      display.classList.add('d-none');
    }
  }

  @ViewChild('parent') private parentRef: ElementRef<HTMLElement> | undefined;

  down(){
    var display=<HTMLElement>document.querySelector(".outerDownShow");
    if(display.classList.contains('mobile-drop-down')==true){
      display.classList.remove('mobile-drop-down')
      display.classList.add('d-none');
      console.log(this.parentRef?.nativeElement.children[0]);
    }else{
      display.classList.add('mobile-drop-down')
      display.classList.remove('d-none');
    }
  }
}
