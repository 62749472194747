<div class="home">
  <!-- <app-menu></app-menu> -->
  <div class="w-100">
    <div class=" home-top-page top-header position-relative " data-menu='true'>
      <div class="bg-myntax smile overflow-hidden position-relative" style="height:100%;">
        <!-- <div class="orange-dot position-absolute background-size-300" style="top:12.5rem;right:0px"></div> -->
        <div class="circle-dark-blue  position-absolute" style="bottom:-80px;right:-28px"></div>
        <div class="display-md-flex w-90 max-width padding-top mx-auto">
          <div class="w-md-50 w-100 pt-md-5 pt-3" style="z-index:100;">
            <div class="text-white mt-md-5 mt-2 myntax-font font-weight-bold" style="font-size: 30px;z-index: 100;">
              <!-- Lorem Ipsum is simply dummy text of the printing and typesetting industry -->
              <h3 class="text-center">Contact Us</h3>
            </div>
            <div class="mt-5">
              <div class="display-flex">
                <div class="w-30 mr-5">
                  <div class="w-100">
                    <img src="assets/img/coin.png" class="w-90" />
                  </div>
                  <div class="text-white text-center">Tax Saving</div>
                </div>
                <div class="w-30 mr-5">
                  <div class="w-100">
                    <img src="assets/img/professional.png" class="w-85" style="margin-top:-13px" />
                  </div>
                  <div class="text-white texty-center">Expert Advice</div>
                </div>
                <div class="w-30">
                  <div class="w-100">
                    <img src="assets/img/phone.png" style="width:79%;margin-top:-9px" />
                  </div>
                  <div class="text-white text-center">Easily Accessible</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-md-50 w-100" style="z-index:100">
            <div class="w-100 text-center">
              <!--text start from here-->
              <div class="text-white text-center">
                <h2 class="font-weight-bold mt-6 font-size-29">We Are Solution To Every<br />Problem For Your Business
                </h2>
                <!-- <div class="w-md-65  mx-auto  my-3">We are india's finest ca firm providing consultancy and service
                  on all your Business requirements whether bet it your tax, Itr, Audit or any thing else we are the
                  solution </div> -->
                <!-- <div class="w-70 mx-auto text-center mt-4"><button class="w-50 bg-secondary-myntax text-white"
                    style="border-radius:20px;border-color: transparent;z-index: 10">Contact Sales</button></div> -->
              </div>
              <!--end of text here-->
              <!--form starts here-->
              <!-- <div class="w-60 mx-auto bg-white position-relative p-4" style="top: 100px;border-radius: 20px;">
                                    <div class="font-weight-bold text-primary-myntax">Fill The Small Form To Get A Call From Us</div>
                                    <div class="my-3">
                                        <input type="text" placeholder="Name" class="w-100 px-4 border-rounded box-sizing-border-box p-1" style="border:1px solid grey;border-radius: 10px;"/>
                                    </div>
                                    <div class="my-3">
                                        <input type="email" placeholder="Email" class="w-100 px-4 box-sizing-border-box p-1" style="border:1px solid grey;border-radius: 10px;"/>
                                    </div>
                                    <div class="my-3">
                                        <input type="tel" placeholder="Phone Number" class="w-100 onlyNumber px-4 box-sizing-border-box p-1" style="border:1px solid grey;border-radius: 10px;"/>
                                    </div>
                                    <div class="text-center">
                                        <button class="my-3 py-2 text-white bg-secondary-myntax w-100" style="border:1px solid transparent;border-radius: 10px;">Submit</button>
                                    </div>
                                </div> -->
              <!--end fo form start here-->
              <!-- <img src="assets/img/men.png" class="flip"/> -->
            </div>
          </div>
        </div>
      </div>
      <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: 10px;right: 9rem;"></div>
      <div class="circle-primary position-absolute"
        style="width: 50px;height: 50px;bottom: -30px;right: 5.5rem;z-index: 1;"></div>
      <div class="circle-secondary position-absolute"
        style="width: 30px;height: 30px;bottom: 32px;right: 4rem;z-index: 1;"></div>
    </div>

    <!-- CONTACT US STARTS HER -->
    <div class="footer-cta my-5  ">
      <div class="w-100 text-gradient-myntax">
        <h3 class="contact-heading text-grey my-5 text-center font-weight-bold myntax-font">Contact Us </h3>
      </div>

      <div class="row px-5 px-md-0 ">
        <div class="col-12 col-md-6">
          <div class="d-flex flex-column justify-content-between ">
            <div class="cta-text text-gradient-myntax pb-5 ">
              <h3 class="myntax-font">Address</h3>
              <span>Office no - 404, 4th floor, B zone, Near vijay sales, Pimpri, Pune, Maharashtra </span>
            </div>

            <div class="cta-text text-gradient-myntax pb-5">
              <h3 class="myntax-font">Contact Number </h3>
              <a href="tel:+917875662288">+91-7875662288 </a>
            </div>

            <div class="cta-text text-gradient-myntax pb-5">
              <h3 class="myntax-font">Email ID</h3>
              <a href="mailto:support@myndtax.in">support@myndtax.in</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 mt-3 mt-md-0 px-5 px-md-0">
          <div class="w-100 w-md-80">
            <form action="" method="post" #clientweb="ngForm" (ngSubmit)="onSubmitPostClient(clientweb.value)">
              <div class="w-100 form-myntax mb-3">
                <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100" ngModel
                  required #name="ngModel">
              </div>
              <div class="w-100 form-myntax mb-3">
                <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Mobile Number" ngModel
                  required #phone="ngModel">
              </div>
              <div class="w-100 form-myntax mb-3">
                <input type="email" name="email" class="w-100" placeholder="Email" ngModel required #email="ngModel" >
              </div>
              <div class="w-100 form-myntax mb-3">
                <textarea class="w-100" placeholder="Type your query here..." name="package_name" rows="3" ngModel required #package_name="ngModel"></textarea>
              </div>


              <div class="text-center my-4">
                <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;"
                  [disabled]="spin">
                  <span *ngIf="!spin">Book Consultancy</span>
                  <span *ngIf="spin"><i class="fas fa-redo"></i></span>
                </button>
              </div>
            </form>
          </div>
          <!--end of form is here-->
        </div>

      </div>






      <!-- <div class="row text-center">
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="single-cta">
            <a href="#"><img src="../../assets/img/map-icon.png" class="cta-img" alt=""></a>
            <div class="cta-text text-gradient-myntax ">
              <h3 class="myntax-font">Address</h3>
              <span>Office no - 404, 4th floor, B zone, Near vijay sales, Pimpri, Pune, Maharashtra </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="single-cta">
            <div class="cta-text text-gradient-myntax">
              <h3 class="myntax-font">Contact Number </h3>
              <a href="tel:+917875662288">+91-7875662288 </a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="single-cta">
            <div class="cta-text text-gradient-myntax">
              <h3 class="myntax-font">Email ID</h3>
              <a href="mailto:support@myndtax.in">support@myndtax.in</a>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- CONTACT US FORM -->
    <!--form start here-->


    <!--end our promise-->














  </div>
</div>
