import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-income-tax-notice',
  templateUrl: './income-tax-notice.component.html',
  styleUrls: ['./income-tax-notice.component.css']
})
export class IncomeTaxNoticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
