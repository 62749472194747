<div class="container" style="min-height: 100vh">

  <div class="card mt-5">
    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <img src="" alt="">
        <h4>Myndtax</h4>
        <h5>Order Summary</h5>
        <ul class="w-md-60 m-auto">
          <li>
            <span>
              Amount
            </span>
            <span>{{(+payload?.amount*82/100).toFixed(0)}}</span>
          </li>
          <li>
            <span>
              GST
            </span>
            <span>{{(+payload?.amount*18/100).toFixed(0)}}</span>
          </li>
          <li>
            <span>
              Total
            </span>
            <span style="font-size: 24px;">INR {{payload?.amount}}</span>
          </li>

      </ul>
    </div>
    <div class="col-sm-6 col-lg-6">
      <div class="checkout-from p-5">
      <h3>Payment Gateway</h3>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" name="" placeholder="Name" [value]="payload?.name" disabled>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" name="" placeholder="Email" [value]="payload?.email" disabled>
        </div>
        <div class="form-group">
          <input type="tel" class="form-control" name="" placeholder="Mobile number" [value]="payload?.phone" disabled>
        </div>
        <button type="button" class="btn" (click)="pay()">Checkout</button>
      </form>
    </div>
    </div>
  </div>
</div>

</div>
