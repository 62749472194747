import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GstAnnualReturnNewComponent } from './gst-annual-return-new/gst-annual-return-new.component';
import { GstLutFillingComponent } from './gst-lut-filling/gst-lut-filling.component';
import { GstRegistrationComponent } from './gst-registration/gst-registration.component';
import { GstReturnFillingComponent } from './gst-return-filling/gst-return-filling.component';
import { TempGstNumberComponent } from './temp-gst-number/temp-gst-number.component';

const routes: Routes = [
  { path: 'gst-annual-return', component: GstAnnualReturnNewComponent },
  { path: 'gst-lut-filling', component: GstLutFillingComponent },
  { path: 'temporary-gst-number', component: TempGstNumberComponent },
  { path: 'gst-return-filling', component: GstReturnFillingComponent },
  { path: 'gst-new-registration', component: GstRegistrationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GstServiceRoutingModule {}
