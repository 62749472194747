import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRoutesService } from 'src/app/api-routes.service';
import { packagePost } from 'src/app/class/heading';

@Component({
  selector: 'app-gstreturnfilling',
  templateUrl: './gstreturnfilling.component.html',
  styleUrls: ['./gstreturnfilling.component.css']
})
export class GstreturnfillingComponent implements OnInit {

  constructor(public _ApiServiceService: ApiRoutesService,private router: Router) { }
  public set_price="7899";
  public basic='1 Year Accounting & GST Filing for business with less than Rs.10 lakhs turnover ';
  public standard="1 Year Accounting & GST Filing for business with Rs.10 lakh - Rs.50 lakh turnover.";
  public set_description=this.basic;

  ngOnInit(): void {
  }

  price(x: string,y: string){
    this.set_price=x;
    this.set_description=y;
  }
  spin = false;

  onSubmitPostClient(data: any) { 
    this.spin = true;
    var opost = new packagePost();
    opost.name = data.name;
    opost.phone = data.phone;
    opost.package_name = "GST Filling";
    opost.email = data.email;
    opost.package_price = this.set_price;

    this._ApiServiceService.postClientData(opost).subscribe(
      (res) => {
        this.spin = false;
        localStorage.setItem('payObject',JSON.stringify(
          {client_id:res.client_id,
            service_id:res.service_id,
            name:opost.name,
            amount:opost.package_price,
            email:data.email,
            phone:data.phone
          }));
        alert(res.message);
        this.router.navigate(['/checkout'])
        .then(() => {
          window.location.reload();
        });
      }
    );
  }
}
