import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-liscense',
  templateUrl: './trade-liscense.component.html',
  styleUrls: ['./trade-liscense.component.css']
})
export class TradeLiscenseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
