import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRoutesService } from 'src/app/api-routes.service';
import { packagePost } from 'src/app/class/heading';

@Component({
  selector: 'app-limited-liability-partnership',
  templateUrl: './limited-liability-partnership.component.html',
  styleUrls: ['./limited-liability-partnership.component.css'],
})
export class LimitedLiabilityPartnershipComponent implements OnInit {
  constructor(
    public _ApiServiceService: ApiRoutesService,
    private router: Router
  ) {}
  public set_price = '7899';
  public basic =
    '1 Year Accounting & GST Filing for business with less than Rs.10 lakhs turnover ';
  public standard =
    '1 Year Accounting & GST Filing for business with Rs.10 lakh - Rs.50 lakh turnover.';
  public set_description = this.basic;

  ngOnInit(): void {
    document
      .querySelector('.btn-consultancy')
      ?.addEventListener('click', () => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });

    document.querySelector('.btn-mobile')?.addEventListener('click', () => {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth',
      });
    });
  }

  price(x: string, y: string) {
    this.set_price = x;
    this.set_description = y;
  }
  spin = false;

  onSubmitPostClient(data: any) {
    this.spin = true;
    var opost = new packagePost();
    opost.name = data.name;
    opost.phone = data.phone;
    opost.package_name = 'Limited Liabilty Partnership';
    opost.email = data.email;
    opost.package_price = this.set_price;

    this._ApiServiceService.postClientData(opost).subscribe((res) => {
      this.spin = false;
      localStorage.setItem(
        'payObject',
        JSON.stringify({
          client_id: res.client_id,
          service_id: res.service_id,
          name: opost.name,
          amount: opost.package_price,
          email: data.email,
          phone: data.phone,
        })
      );
      alert(
        'Thank you for contacting us. Our tax expert will connect you shortly.'
      );
      // this.router.navigate(['/checkout']).then(() => {
      //   window.location.reload();
      // });
    });
  }
}
