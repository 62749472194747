import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msme-registration',
  templateUrl: './msme-registration.component.html',
  styleUrls: ['./msme-registration.component.css']
})
export class MsmeRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
