import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Form16IssuanceComponent } from './form16-issuance/form16-issuance.component';
import { IncomeTaxNoticeComponent } from './income-tax-notice/income-tax-notice.component';
import { ItrFilingComponent } from './itr-filing/itr-filing.component';
import { LimitedLiabilityPartnershipComponent } from './limited-liability-partnership/limited-liability-partnership.component';
import { PrivateLimitedCompanyComponent } from './private-limited-company/private-limited-company.component';
import { ProprietorShipComponent } from './proprietor-ship/proprietor-ship.component';
import { TdsReturnFilingComponent } from './tds-return-filing/tds-return-filing.component';

const routes: Routes = [
  { path: 'incometax-proprietorship-it', component: ProprietorShipComponent },
  {
    path: 'incometax-privatelimitedcompany',
    component: PrivateLimitedCompanyComponent,
  },
  {
    path: 'incometax-limitedliabilitypartnership',
    component: LimitedLiabilityPartnershipComponent,
  },
  { path: 'incometax-itr-filing', component: ItrFilingComponent },
  { path: 'incometax-incometaxnotice', component: IncomeTaxNoticeComponent },
  { path: 'incometax-tdsreturnfiling', component: TdsReturnFilingComponent },
  { path: 'incometax-form16issuance', component: Form16IssuanceComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IncomeTaxRoutingModule {}
