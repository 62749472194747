import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-mark-opposition',
  templateUrl: './trade-mark-opposition.component.html',
  styleUrls: ['./trade-mark-opposition.component.css']
})
export class TradeMarkOppositionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
