<div class="w-100 pt-2 text-grey box-sizing-border-box bg-white font-size-14 box-shadow-1 d-none d-md-block"
  style="position: fixed;top:50px;z-index:10001;">
  <div class="w-90 mx-auto pb-2 px-3  display-flex">
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">GST Registration</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Types of Registration</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Gst Compliances</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Steps to Register</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Price Of Registration</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Documents Required</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">FAQ</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Our Customer Satisfaction</div>
  </div>
  <div class="w-100">
    <div class="bg-secondary-myntax set-loader" style="border-radius: 10px;height: 5px;width:0%;"></div>
  </div>
</div>
<div class="set-max-100 position-relative">
  <div class="top-header" data-menu='false' style="padding-top: 60px;"></div>
  <div class="">
    <!--dextop view header-->
    <div class="mx-auto d-md-block d-none max-width">
      <div class="display-flex display-md- mt-5">
        <!--main body of limited liability partner ship-->
        <div class="w-70 position-relative">
          <div class="text-gradient-myntax ">
            <h1 class="font-weight-bold" style="font-size:30px">GST Registration</h1>
          </div>
          <!--rating and price-->
          <div class="w-100 display-flex">
            <!--form start here-->
            <div class="w-40 pr-5 box-sizing-border-box position-relative">
              <div class="mt-4 w-100">
                <form action="" method="post" #client="ngForm" (ngSubmit)="onSubmitPostClient(client.value)">
                  <div class="w-100 form-myntax mb-3">
                    <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100"
                      ngModel required #name="ngModel">
                  </div>
                  <div class="w-100 form-myntax mb-3">
                    <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel required
                      #phone="ngModel">
                  </div>
                  <div class="w-100 form-myntax mb-3">
                    <input type="email" name="email" class="w-100" placeholder="Email" ngModel required
                      #email="ngModel">
                  </div>
                  <div class="text-center my-4">
                    <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;"
                      [disabled]="spin">
                      <span *ngIf="!spin">Book Consultancy</span>
                      <span *ngIf="spin"><i class="fas fa-redo"></i></span>
                    </button>
                  </div>
                </form>
              </div>
              <div class="circle-secondary position-absolute"
                style="width: 40px;height: 40px;bottom: -102px;right: 9rem;"></div>
              <div class="circle-primary position-absolute"
                style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
              <div class="circle-secondary position-absolute"
                style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
            </div>
            <!--end of form is here-->
            <!--start of quote-->
            <!-- <div class="w-60 px-3 box-sizing-border-box">
              <div class="w-100 display-flex">
                <div class="w-50">
                  <i class="fa fa-star text-warning mr-1" style="font-size: 13px;" *ngFor="let i of [1,2,3,4,5];"></i>
                </div>
                <div class="w-50 text-right">
                  <span style="font-size: 14px;" class="font-weight-bold">Rs {{set_price}}</span><br />
                  <span class="text-grey">Inclusive all the Taxes</span>
                </div>
              </div>
              <div class="w-100 mt-3 text-grey text-capitalize" style="border-top: 1px solid lightgrey;">
                <div>
                  <span class="text-grey" style="font-size: 13px;">Select Package</span><br />
                  <button class="mr-3 p-1 px-2 button-selected hprbtn" (click)="price('988',basic)">Basic</button>
                  <button class="mr-3 p-1 px-2  button-not-selected hprbtn"
                    (click)="price('2899',standard)">standard</button>
                </div>
                <div class="my-3" style="font-size: 16px;">
                  {{set_description}}
                </div>
              </div>
            </div> -->
            <!--end of quote-->
          </div>
          <!--end rating and price-->

        </div>
        <!--end of main body of unlimited liability-->
        <div class="w-30">
          <!--start of v2 header of the image-->
          <div class="mx-auto w-100 setafterimage">
            <img src="assets/img/plc.png" class="w-100" />
          </div>
          <!--end of v2 header of the image-->
          <!-- <div class="mx-auto w-65 side-images">
                            <div class="image-rotater">
                                <img src="assets/img/private-limited-asset.jpg" alt="Private limited company"  class="" >
                            </div>
                        </div>
                        <div class="w-50 image-container position-relative add-side-full-square" style="height: 300px;;top:8rem">
                            <div class="grey-dot position-absolute" style="width:100px;height:100px;bottom:0px;right:-7rem"></div>
                            <div class="position-absolute" style="right: -15rem;bottom: -10rem;">
                                <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-2rem"></div>
                                <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
                            </div>
                        </div> -->
        </div>
      </div>
    </div>
    <!--end of dextop view header-->
    <!--mobile version header-->
    <div class="w-100">
      <div class=" w-100 p-2 d-md-none d-block box-sizing-border-box">
        <!--img setting-->
        <div class="w-100">
          <img src="../../../assets/img/gst-registration-m.webp" width="100%" class="border-rounded" alt="">
        </div>
        <div class="text-gradient-myntax mt-4">
          <h1 class="font-weight-bold" style="font-size:30px">GST Registration</h1>
        </div>
        <!--description-->
        <div class="text-justify w-100 mx-auto">
          <div class="w-100 px-3 box-sizing-border-box">
            <div class="w-100 display-flex">
              <div class="w-50">
                <i class="fa fa-star text-warning mr-1" style="font-size: 13px;" *ngFor="let i of [1,2,3,4,5];"></i>
              </div>
              <div class="w-50 text-right">
                <span style="font-size: 14px;" class="font-weight-bold">Rs {{set_price}}</span><br />
                <span class="text-grey">Inclusive all the Taxes</span>
              </div>
            </div>
            <div class="w-100 mt-3 text-grey text-capitalize" style="border-top: 1px solid lightgrey;">
              <div>
                <span class="text-grey" style="font-size: 13px;">Select Package</span><br />
                <button class="mr-3 p-1 px-2 button-selected hprbtn" (click)="price('988',basic)">Basic</button>
                <button class="mr-3 p-1 px-2  button-not-selected hprbtn"
                  (click)="price('2899',standard)">standard</button>
              </div>
              <div class="my-3" style="font-size: 16px;">
                {{set_description}}
              </div>
            </div>
          </div>
        </div>
        <!--end of description-->
        <!--forms-->
        <div class="w-90 mx-auto box-sizing-border-box position-relative">
          <div class="mt-4 w-100">
            <form action="" method="post" #clientPhone="ngForm" (ngSubmit)="onSubmitPostClient(clientPhone.value)">
              <div class="w-100 form-myntax mb-3">
                <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100" ngModel
                  required #name="ngModel">
              </div>
              <div class="w-100 form-myntax mb-3">
                <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel required
                  #phone="ngModel">
              </div>
              <div class="w-100 form-myntax mb-3">
                <input type="email" name="email" class="w-100" placeholder="Email" ngModel required #email="ngModel">
              </div>
              <div class="text-center my-4">
                <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;">Book
                  Consultancy</button>

              </div>
            </form>
          </div>
          <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -102px;right: 9rem;">
          </div>
          <div class="circle-primary position-absolute"
            style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
          <div class="circle-secondary position-absolute"
            style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
        </div>
        <!--end of forms-->
      </div>
    </div>
    <!--end of mobile version header-->
    <!--end of header tag-->
    <div class="w-100 position-relative mt-5" style="z-index: 1000;margin-top:150px !important;">
      <div class="w-md-80 w-100 mx-auto box-shadow-1 bg-myntax white-dot gst-reg-1a">
        <div class="row">
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-white text-center">Total Clients</div>
            <div class="text-white text-center font-weight-bold">5000+</div>
          </div>
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-white text-center">Active Clients</div>
            <div class="text-white text-center font-weight-bold">6000+</div>
          </div>
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-white text-center">Compliances Filled</div>
            <div class="text-white text-center font-weight-bold">7000+</div>
          </div>
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-center text-white">LLP Registered</div>
            <div class="text-center text-white font-weight-bold">8000+</div>
          </div>
        </div>
      </div>
      <div class="mx-auto max-width my-5 py-5 top1">
        <div class="display-md-flex display-block w-md-100 w-90 mx-auto">
          <!--image container-->
          <div class="w-md-50 w-100">
            <div class="p-2  box-sizing-border-box">
              <div class="mx-auto d-none d-md-block text-center position-relative scrollSide">
                <img src="assets/img/llp-registration.png" height="400px" />
              </div>
              <!--mobile version-->
              <div class="w-100 d-md-none">
                <img src="../../../assets/img/gst-registration.jpg" style="width:100% !important;border-radius:10px;"
                  alt="">
              </div>
              <!--end of mobile version-->
            </div>
          </div>
          <!--text container-->
          <div class="w-md-50 w-100 text-height">
            <div class="w-100 text-gradient-myntax">
              <h3 class="text-grey my-4 myntax-font">GST Registration </h3>
            </div>
            <!--llp registration -->
            <div class="my-4 text-grey ">
              <div class="">
                GST is the product of the biggest tax reform in India which has tremendously improved ease of doing
                business and increasing the taxpayer base in India by including millions of small businesses. Tax
                complexities would be reduced due to the abolishing and subsuming of multiple taxes into a single,
                simple system.
                <br /><br />
                The new GST regime mandates that all entities involved in buying or selling goods or providing services
                or both are required to register and obtain GSTIN. Registration is mandatory once the entity crosses a
                minimum threshold turnover or when an individual starts a new business that is expected to cross the
                prescribed turnover of 40 Lakh
                <br /><br />
                The new GST regime mandates that all entities involved in buying or selling goods or providing services
                or both are required to register and obtain GSTIN. Registration is mandatory once the entity crosses a
                minimum threshold turnover or when an individual starts a new business that is expected to cross the
                prescribed turnover.
              </div>
              <div class="text-center my-4">
                <button class="bg-secondary-myntax w-md-40 w-60 p-2 text-white"
                  style="border-radius: 20px;border:0px;">Book Consultancy</button>
              </div>
            </div>
            <!--end of llp registration-->
          </div>
        </div>
      </div>
      <!--end of about limited liability partnership-->
      <!--feature of limited liability partnership-->
      <div class="max-width mx-auto w-100 top1">
        <div class="w-100 text-gradient-myntax">
          <h3 class="text-grey my-4 myntax-font text-center">Types of GST Registration </h3>
        </div>
        <div class="mt-3 w-md-80 w-95 mx-auto why-after position-relative">
          <!--list-->
          <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow"
            style="border-left:5px solid #ff914d">
            <label class="text-myntax font-weight-bold mr-4">Normal Taxpayer :</label>
            <span class="text-grey" style="font-size: inherit;">Most businesses in India fall under this category. You
              need not provide any deposit to become a normal taxpayer. There is also no expiry date for taxpayers who
              fall under this category.</span>
          </div>
          <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow"
            style="border-left:5px solid #ff914d">
            <label class="text-myntax font-weight-bold mr-4">Casual Taxable Person :</label>
            <span class="text-grey" style="font-size: inherit;">Individuals who wish to set up a seasonal shop or stall
              can opt for this category. You must deposit an advance amount that is equal to the expected GST liability
              during the time the stall or seasonal shop is operational. The duration of the GST Registration under this
              category is 3 months and it can be extended or renewed.</span>
          </div>
          <div class="grey-dot position-absolute " style="top:4rem;left:-7rem"></div>
          <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow"
            style="border-left:5px solid #ff914d">
            <label class="text-myntax font-weight-bold mr-4">Composition Taxpayer : </label>
            <span class="text-grey" style="font-size: inherit;">Apply for this if you wish to obtain the GST Composition
              Scheme. You will have to deposit a flat under this category. Input tax credit cannot be obtained under
              this category.</span>
          </div>
          <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow"
            style="border-left:5px solid #ff914d">
            <label class="text-myntax font-weight-bold mr-4">Non-Resident Taxable Person : </label>
            <span class="text-grey" style="font-size: inherit;">If you live outside India, but supply goods to
              individuals who stay in India, opt for this type of GST Registration. Similar to the Casual Taxable Person
              type, you must pay a deposit equal to the expected GST liability during the time the GST registration is
              active. The duration for this type of GST registration is usually 3 months, but it can be extended or
              renewed at the type of expiry. </span>
          </div>
          <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow"
            style="border-left:5px solid #ff914d">
            <label class="text-myntax font-weight-bold mr-4">Voluntary registration: </label>
            <span class="text-grey" style="font-size: inherit;">Any entity can obtain GST registration voluntarily.
              Earlier, any entity who obtained GST voluntarily could not surrender the registration for up to a year.
              However, after revisions, voluntary GST registration can be surrendered by the applicant at any
              time.</span>
          </div>
          <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-3rem"></div>
          <div class="circle-secondary position-absolute"
            style="width:40px;height:40px;bottom:55px;right:-1rem;z-index: 1;"></div>
          <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
          <!--end of list-->
        </div>
      </div>
      <!--end of limited liability partnership-->
      <!--benefits of limited liability partnership-->
      <div class="mx-auto max-width my-5 pt-5 top1">
        <!--text-->
        <div class="display-md-flex">
          <div class="w-100 w-md-50">
            <div class="w-100 text-gradient-myntax">
              <h3 class="text-grey my-4 myntax-font text-center">GST Compliance </h3>
            </div>
            <!--mobile version -->
            <div class="w-100 d-md-none">
              <div class="mt-5 text-center">
                <img src="assets/img/benefits-of-llp.png" class="w-80" />
              </div>
            </div>
            <!--end of mobile versioni-->
            <div class="p-2 box-sizing-border-box position-relative">
              <div class="grey-dot position-absolute" style="top:4rem;left:-8rem"></div>
              <!--points starting from here-->
              <div class="mt-3 w-100">
                <div class="text-grey">All companies registered Under GST in India are required to maintain compliance
                  under various regulations. Failure to Filling of GST return can lead to penalty on the company or the
                  owner of the company</div>
              </div>
              <div class="mt-3 w-100">
                <div class="text-grey">A GST Return Filing is a return document that contains details of the income of
                  the taxpayer. It has to be filed with the GST administrative authority. The document is used tax
                  authorities to calculate the tax liability of a GST taxpayer. A GST Return Filing form has to include
                  the following details..</div>
              </div>
              <div class="mt-3 w-100">
                <div class="text-grey">Some of the important compliances for companies registered Under GST in India are
                  given. </div>
              </div>
              <div class="mt-3 w-100">
                <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                <label class="text-secondary-myntax font-weight-bold">GSTR 1</label>
              </div>
              <div class="w-100">
                <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                <label class="text-secondary-myntax font-weight-bold">GSTR 2</label>
              </div>
              <div class="w-100">
                <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                <label class="text-secondary-myntax font-weight-bold">GSTR 3B</label>
              </div>
              <div class="w-100">
                <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                <label class="text-secondary-myntax font-weight-bold">Annual Return</label>
              </div>
              <div class="w-100">
                <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                <label class="text-secondary-myntax font-weight-bold">GSTR 9A</label>
              </div>
              <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white"
                  style="font-size: 15px;border:0px;border-radius: 20px;">Book Now</button></div>
              <div class="circle-secondary position-absolute" style="width:40px;height:40px;bottom:55px;right:-1rem">
              </div>
              <div class="circle-primary position-absolute"
                style="width:50px;height:50px;bottom:55px;right:-4rem;z-index:1;"></div>
              <div class="circle-secondary position-absolute"
                style="width:30px;height:30px;bottom:-9px;right:-6rem;z-index: 1;"></div>
              <!--end of points here-->
            </div>
          </div>
          <div class="w-50 pt-5 position-relative d-md-block d-none">
            <div class="mt-5 text-center">
              <img src="assets/img/benefits-of-llp.png" class="w-80" />
            </div>
          </div>
        </div>
        <!--letter-->
      </div>
      <!--end of benefits of limited liability partnership-->
      <!--steps for Registration llp-->
      <div class="w-100 my-3 position-relative top1">
        <div class="bg-myntax w-100 overflow-hidden trans-div position-relative" style="min-height: 600px;">
          <div class="text-center max-width mx-auto py-5">
            <div class="text-center text-white">
              <h4>Steps to Register GST</h4>
            </div>
          </div>
          <div class="max-width mx-auto w-100" style="margin-bottom: 180px;">
            <!--first column-->
            <div class="row my-3 ml-5">
              <div class="col-md-4 col-12">
                <!--image container-->
                <div class="w-100">
                  <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                    <img src="assets/img/save-money.png" class="w-70 position-relative" style="top:10px" />
                  </div>
                </div>
                <!--heading for card-->
                <div class="w-100 my-4 font-weight-bold text-white">Purchase of Plan</div>
                <div class="text-white w-80 text-justify">
                  Purchase one of Our plan and make the payment online using any payment method
                </div>
              </div>
              <div class="col-md-4 mt-4 mt-md-0 col-12">
                <!--image container-->
                <div class="w-100">
                  <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                    <img src="assets/img/teacher.png" class="w-70 position-relative" style="top:10px" />
                  </div>
                </div>
                <!--heading for card-->
                <div class="w-100 my-4 font-weight-bold text-white">Expert Assigned</div>
                <div class="text-white w-80 text-justify">
                  One expert will be assigned to you for better understanding of your company and need and he will
                  proceed with the procedure
                </div>
              </div>
              <div class="col-md-4 mt-4 mt-md-0 col-12">
                <!--image container-->
                <div class="w-180">
                  <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                    <img src="assets/img/identity.png" class="w-60 position-relative" style="top:18px" />
                  </div>
                </div>
                <!--heading for card-->
                <div class="w-100 my-4 font-weight-bold text-white">Upload the Document</div>
                <div class="text-white w-80 text-justify">
                  Upload the necessary document that have been asked from you and you are task is finished
                </div>
              </div>
              <!--first column-->
              <div class="col-md-4 mt-4 mt-md-2 col-12">
                <!--image container-->
                <div class="w-100">
                  <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                    <img src="assets/img/preparation.png" class="w-60 position-relative" style="top:14px" />
                  </div>
                </div>
                <!--heading for card-->
                <div class="w-100 my-4 font-weight-bold text-white">Prepare Document</div>
                <div class="text-white w-80 text-justify">
                  Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                </div>
              </div>
              <div class="col-md-4 mt-4 mt-md-2 col-12">
                <!--image container-->
                <div class="w-100">
                  <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                    <img src="assets/img/contract.png" class="w-60 position-relative" style="top:15px;left:3.5px" />
                  </div>
                </div>
                <!--heading for card-->
                <div class="w-100 my-4 font-weight-bold text-white">Arn Number is Generated </div>
                <div class="text-white w-80 text-justify">
                  After the prepared document has been submitted to the Arn will be Generated which you can use to track
                  later on
                </div>
              </div>
              <div class="col-md-4 mt-4 mt-md-2 col-12">
                <!--image container-->
                <div class="w-180">
                  <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                    <img src="assets/img/card.png" class="w-70 position-relative" style="top:18px" />
                  </div>
                </div>
                <!--heading for card-->
                <div class="w-100 my-4 font-weight-bold text-white">GSTIN Received</div>
                <div class="text-white w-80 text-justify">
                  After the successfully acceptance of Documents GSTIN will be Generated
                </div>
              </div>
              <!--end of first column-->
            </div>
            <!--end of first column-->
          </div>
        </div>
        <div class="grey-dot position-absolute " style="bottom: 4rem;left: 2rem;"></div>
        <div class="grey-dot position-absolute " style="top: -3rem;right: 10rem;"></div>
        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 9rem;">
        </div>
        <div class="circle-primary position-absolute"
          style="width: 50px;height: 50px;bottom: 17px;right: 6rem;z-index: 1;"></div>
        <div class="circle-secondary position-absolute"
          style="width: 30px;height: 30px;bottom: -2px;right: 4rem;z-index: 1;"></div>
      </div>
      <!--end of steps for Registration llp-->
      <!-- <div class="mx-auto max-width w-100 top1">
        <div class="w-100 text-gradient-myntax">
          <h3 class="text-grey my-4 myntax-font text-center">GST (Goods and Services Tax) Registration Cost </h3>
        </div>
        <div class="my-3 w-100">
          <div class="mx-auto w-md-80 w-95">
            <div class="display-md-flex ml-5 mx-auto position-relative llp-cost">
              pricing //COMMENTED
              <div class="w-md-50 w-100 position-relative">
                <div class="w-md-70 w-100 mx-auto light-box-shadow position-relative">
                  <div class="circle-secondary position-absolute"
                    style="width: 40px;height: 40px;bottom: -2px;right: 0rem;z-index:2;"></div>
                  <div class="circle-primary position-absolute"
                    style="width: 50px;height: 50px;bottom: 17px;right: -3rem;z-index: 1;"></div>
                  <div class="circle-secondary position-absolute"
                    style="width: 30px;height: 30px;bottom: -2px;right: -4rem;z-index: 1;"></div>
                  <div class="grey-dot position-absolute" style="bottom : 3rem;left: -8rem;"></div>
                  <div class="w-100 p-4 box-sizing-border-box bg-secondary-myntax">
                    <div class=" text-white font-weight-600 text-center" style="font-size: 22px;;">Basic</div>
                    <div class="text-center text-white position-relative">
                      <i class="fa fa-inr position-absolute" style="top:18px"></i><span class="font-weight-bold ml-3"
                        style="font-size:40px">988</span>
                      <div class="text-center text-white">All exclusive fees</div>
                    </div>
                  </div>
                  <div class="p-4 box-sizing-border-box">
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">Digital Signature</div>
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">GST Registration</div>
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">Business Bank Account</div>
                    <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white"
                        style="font-size: 15px;border:0px;border-radius: 20px;">Book Now</button></div>
                  </div>
                </div>
              </div>
              <div class="blue-dot position-absolute background-size-300" style="top : 3rem;right: -6rem;"></div>
              <div class="grey-dot position-absolute background-size-300" style="bottom: 3rem;right: -6rem;"></div>
              <div class="circle-secondary position-absolute"
                style="width: 40px;height: 40px;bottom: -2px;right: 5rem;z-index:2;"></div>
              <div class="circle-primary position-absolute"
                style="width: 50px;height: 50px;bottom: 17px;right: 2rem;z-index: 1;"></div>
              <div class="circle-secondary position-absolute"
                style="width: 30px;height: 30px;bottom: -2px;right: 1rem;z-index: 1;"></div>
              pricing start for second one //COMMENTED
              <div class="w-md-50 mt-5 mt-md-0 w-100">
                <div class="w-md-70 w-100 mx-auto light-box-shadow">
                  <div class="w-100 p-4 box-sizing-border-box bg-secondary-myntax">
                    <div class=" text-white font-weight-600 text-center" style="font-size: 22px;;">Standard</div>
                    <div class="text-center text-white position-relative">
                      <i class="fa fa-inr position-absolute" style="top:18px"></i><span class="font-weight-bold ml-3"
                        style="font-size:40px">2899</span>
                      <div class="text-center text-white">All exclusive fees</div>
                    </div>
                  </div>
                  <div class="p-4 box-sizing-border-box">
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">Digital Signature</div>
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">GST Registration</div>
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">Business Bank Account</div>
                    <div class="text-center text-grey font-border p-2 box-sizing-border-box">6 Months GST Filling</div>
                    <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white"
                        style="font-size: 15px;border:0px;border-radius: 20px;">Book Now</button></div>
                  </div>
                </div>
              </div>
              end of pricing of second one //COMMENTED
              end of pricing //COMMENTED
            </div>
          </div>
        </div>
      </div> -->
      <!--document required-->
      <div class="mx-auto max-width my-5 top1">
        <div class="display-md-flex w-100">
          <!--image container-->
          <div class="w-50">
            <div class="p-2 pt-5 box-sizing-border-box position-relative">
              <div class="mx-auto d-md-block d-none text-center position-relative scrollSide">
                <img src="assets/img/llp-registration.png" height="400px" />
              </div>
              <div class="circle-secondary position-absolute"
                style="width: 40px;height: 40px;bottom: -2px;right: 5rem;z-index:2;"></div>
              <div class="circle-primary position-absolute"
                style="width: 50px;height: 50px;bottom: 17px;right: 2rem;z-index: 1;"></div>
              <div class="circle-secondary position-absolute"
                style="width: 30px;height: 30px;bottom: -2px;right: 1rem;z-index: 1;"></div>
              <div class="grey-dot position-absolute" style="bottom : 0rem;left: 8rem;"></div>
            </div>
            <!--mobile view-->
            <div class="w-100 d-md-none">
              <div class="w-100">
                <img src="assets/img/llp-registration.png" height="400px" />
              </div>
            </div>
            <!--end of mobile view-->
          </div>
          <!--text container-->
          <div class="w-md-50 w-90 mx-auto text-height pt-4">
            <div class="w-100 my-5 text-gradient-myntax">
              <h3 class="text-grey my-4 myntax-font">Documents Required In GST Registration </h3>
            </div>
            <!--llp registration -->
            <div class="my-4 text-grey ">
              <div class="">
                <div class="my-4 ">The Goods And Services Tax (GST) Registration services at Myndtax helps you to get
                  your business GST registered and obtain your GSTIN.<br /><br />
                  ClearTax GST experts will guide you on the applicability and compliances under GST for your business
                  and get your business registered under GST.</div>
                <ul>
                  <li>PAN of the Applicant</li>
                  <li>Address Proof of the place of business</li>
                  <li>Aaddhar Card </li>
                  <li>Bank Account Statement/Cancelled cheque</li>
                  <li>Proof of business registration of Incorporation certificate</li>
                  <li>Identity and Address proof of Promoters/Director with Photographs</li>
                  <li>Letter</li>
                </ul>
              </div>
              <div class="text-center my-4">

                <button class="bg-secondary-myntax w-md-40 w-60 p-2 text-white  btn-consultancy"
                  style="border-radius: 20px;border:0px;">Book Consultancy</button>
                <button class="bg-secondary-myntax w-md-40 w-60 p-2 text-white  btn-mobile"
                  style="border-radius: 20px;border:0px;">Book Consultancy</button>
              </div>
            </div>
            <!--end of llp registration-->
          </div>
        </div>
      </div>
      <!--end of Document Required-->
      <!--start of frequently asked question-->
      <div class="mx-auto max-width my-5 pt-5 top1">
        <div class="w-100 text-center my-5 text-gradient-myntax">
          <h3 class="text-grey my-4 myntax-font">Frequently Asked Question </h3>
        </div>
        <div class="w-80 mx-auto position-relative">
          <!--start of frequently asked question div-->
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                What is the eligibility criteria ?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                Any existing taxpayer whose annual turnover did not cross the Rs.1.5 crore threshold in the preceding
                financial year. However, service providers with the exception of restaurants and caterers are not
                eligible, neither are casual taxable persons nor non-resident Indians.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                Can input tax credit be claimed under composition scheme ?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                No input tax credit can be claimed by a dealer opting for composition scheme as he is out of the credit
                chain. He cannot take credit on his input supplies.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                How Long Will the composition scheme be valid?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                The validity of the composition scheme will depend upon the option exercised by a taxable person as long
                as all the conditions are fulfilled as specified in the law. However, individuals who are eligible for
                the scheme can calso hoose to opt-out of it by simply filing an application.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                Can I apply for multiple GST Registration
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                If a business operates from more than one state, the taxpayer should obtain a separate GST registration
                for each state. For instance, If an automobile company sells in Karnataka and Tamil Nadu, he has to
                apply for separate GST registration in Karnataka and Tamilnadu respectively.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                What turnover should be considered for GST registration threshold limit ?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                Aggregate turnover should be taken into consideration to calculate turnover. Aggregate turnover means
                the aggregate value of all taxable supplies excluding inward supplies liable to reverse charge, but
                including exempt supplies, exports of goods or services or both and inter-state supplies of persons
                having the same PAN, to be computed on an all-India basis. It is to be noted that CGST, SGST, UTGST,
                IGST and cess should be excluded while calculating aggregate turnover.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                What is inter-state supply ?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                Inter-state supply of goods or service is when the supply location is a different state from the
                delivery location. In addition, the inter-state supply applies to the supply of goods or services by an
                SEZ unit or the export of goods or services.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                What is E-way bill ?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                An E-way bill is an electronic document which serves as an evidence to the movement of goods having a
                value of more than Rs. 50,000. It available to a supplier or an individual transporting goods. It has
                two components; Part A, with details such a GSTIN of the supplier and recipient, place of delivery,
                value of goods, HSN code, reason for transportation and part B, with details of the vehicle and
                transport documents.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                When Should E way Bill Be generated
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                As per rule 138 of the CGST Rules, 2017, an e-way bill has to be generated prior to the commencement of
                transport of goods.
              </div>
            </div>
          </div>
          <div class="w-100 mb-3 light-box-shadow">
            <!--question div-->
            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
              <i class="fa fa-angle-double-right text-grey mr-3"></i>
              <span class="text-grey font-weight-bold" style="font-size: 14px;">
                What is intra-state supply ?
              </span>
              <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
            </div>
            <!--answer div-->
            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1"
              style="overflow: hidden;height:0px ;">
              <div class="pb-3" style="font-size: 14px;">
                An intra-state supply of goods or service applies when the place of supply is in the same state as the
                location of the supplier. Intra-state supply does not include the supply of goods/service to SEZ units
                or developers, imports or exports.
              </div>
            </div>
          </div>
          <!--end of the frequently asked question-->
          <div class="circle-secondary position-absolute"
            style="width: 40px;height: 40px;bottom: -2px;right: 0rem;z-index:2;"></div>
          <div class="circle-primary position-absolute"
            style="width: 50px;height: 50px;bottom: 17px;right: -3rem;z-index: 1;"></div>
          <div class="circle-secondary position-absolute"
            style="width: 30px;height: 30px;bottom: -2px;right: -4rem;z-index: 1;"></div>
          <div class="grey-dot position-absolute" style="bottom : 23rem;left: -7rem;"></div>
        </div>
      </div>
      <!--end of frequently asked question-->
      <!--Customer review-->
      <div class="w-100 max-width mx-auto py-3 top1">
        <div class=" mb-5 font-weight-bold text-gradient-myntax text-center">
          <h3 class="customer-satisfaction" style="font-size: 25px;">Our customer Satisfaction</h3>
        </div>
        <div class="mx-auto mt-5 w-80">
          <!-- start of customer review-->
          <div class="w-100 display-md-flex mx-auto pt-5">
            <!--start customer review box-->
            <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box"
              style="border-radius: 20px;">
              <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                <img src="../../assets/img/ramesh.jpeg" />
              </div>
              <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Ramesh
                Arolkar
              </div>
              <div class="w-100 text-center">
                <i class="fas fa-star mr-2 text-tertiary-myntax"
                  *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
              </div>
              <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Very good service
                and good Co-ordination. Very much satisfied.</div>
            </div>
            <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box"
              style="border-radius: 20px;">
              <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                <img src="../../assets/img/venugopal.jpeg" />
              </div>
              <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Venugopal
                Pillai
              </div>
              <div class="w-100 text-center">
                <i class="fas fa-star mr-2 text-tertiary-myntax"
                  *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
              </div>
              <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Very Easy and like
                the complete ownership take by your team. Thank you very much.</div>
            </div>
            <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box"
              style="border-radius: 20px;">
              <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                <img src="../../assets/img/nitin.jpeg" />
              </div>
              <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Nitin Kadam
              </div>
              <div class="w-100 text-center">
                <i class="fas fa-star mr-2 text-tertiary-myntax"
                  *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
              </div>
              <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Really appreciate
                your timely service, which helps me a lot. Thank you very much.</div>
            </div>
            <!--end of customer review box-->
          </div>
        </div>
      </div>
      <!--end of customer review-->
      <div class="w-100 mx-auto">
        <!-- <svg height="100%" width="100%" id="bg-svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg"
          class="transition duration-300 ease-in-out delay-150">
          <defs>
            <linearGradient id="gradient">
              <stop offset="5%" stop-color="#002bdc88"></stop>
              <stop offset="95%" stop-color="#32ded488"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 0,600 C 0,600 0,200 0,200 C 76.88995215311004,177.6937799043062 153.7799043062201,155.38755980861242 251,163 C 348.2200956937799,170.61244019138758 465.7703349282298,208.14354066985646 559,224 C 652.2296650717702,239.85645933014354 721.1387559808611,234.03827751196175 811,220 C 900.8612440191389,205.96172248803825 1011.6746411483255,183.70334928229664 1120,179 C 1228.3253588516745,174.29665071770336 1334.1626794258373,187.14832535885168 1440,200 C 1440,200 1440,600 1440,600 Z"
            stroke="none" stroke-width="0" fill="url(#gradient)"
            class="transition-all duration-300 ease-in-out delay-150"></path>
          <defs>
            <linearGradient id="gradient">
              <stop offset="5%" stop-color="#002bdcff"></stop>
              <stop offset="95%" stop-color="#32ded4ff"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 0,600 C 0,600 0,400 0,400 C 97.01435406698562,410.2200956937799 194.02870813397124,420.4401913875598 293,400 C 391.97129186602876,379.5598086124402 492.8995215311005,328.4593301435407 600,326 C 707.1004784688995,323.5406698564593 820.3732057416267,369.72248803827756 911,398 C 1001.6267942583733,426.27751196172244 1069.6076555023924,436.6507177033493 1154,434 C 1238.3923444976076,431.3492822966507 1339.1961722488038,415.67464114832535 1440,400 C 1440,400 1440,600 1440,600 Z"
            stroke="none" stroke-width="0" fill="url(#gradient)"
            class="transition-all duration-300 ease-in-out delay-150"></path>
        </svg> -->
      </div>
    </div>
  </div>
</div>
