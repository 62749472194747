import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComplianceRoutingModule } from './compliance-routing.module';
import { RegistrationOfficeChangeComponent } from './registration-office-change/registration-office-change.component';
import { AddDirectorsComponent } from './add-directors/add-directors.component';
import { RemoveDirectorsComponent } from './remove-directors/remove-directors.component';
import { ShareTransferComponent } from './share-transfer/share-transfer.component';
import { IncreaseAuthorizedCapitalComponent } from './increase-authorized-capital/increase-authorized-capital.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RegistrationOfficeChangeComponent,
    AddDirectorsComponent,
    RemoveDirectorsComponent,
    ShareTransferComponent,
    IncreaseAuthorizedCapitalComponent,
  ],
  imports: [CommonModule, ComplianceRoutingModule, FormsModule],
})
export class ComplianceModule {}
