 <app-colored-menu></app-colored-menu>
<div class="set-max-100 position-relative">
    <div class="top-header"  data-menu='false' style="padding-top: 60px;" ></div>
    <div class="">
            <div class="mx-auto max-width">
                <div class="display-flex mt-5">
                    <!--main body of limited liability partner ship-->
                    <div class="w-70 position-relative">
                        <div class="text-gradient-myntax ">
                            <h1 class="font-weight-bold" style="font-size:30px">Register Private Limited Company </h1>
                        </div>
                        <!--rating and price-->
                        <div class="w-100 display-flex">
                            <!--form start here-->
                            <div class="w-40 pr-5 box-sizing-border-box position-relative" >
                                <div class="mt-4 w-100">
                                    <form action="" method="post">
                                        <div class="w-100 form-myntax mb-3">
                                            <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100" required/>
                                        </div>
                                        <div class="w-100 form-myntax mb-3">
                                            <input type="tel" class="onlyNumber" class="w-100" placeholder="Phone" required/>
                                        </div>
                                        <div class="w-100 form-myntax mb-3">
                                            <input type="email"  class="w-100" placeholder="Email" required />
                                        </div>
                                        <div class="text-center my-4">
                                            <button class="bg-secondary-myntax w-100 p-1 text-white" style="border-radius: 5px;border:0px;">Book Consultancy</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -102px;right: 9rem;"></div>
                                <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
                                <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
                            </div>
                            <!--end of form is here-->
                            <!--start of quote-->
                            <div class="w-60 px-3 box-sizing-border-box">
                                <div class="w-100 display-flex">
                                    <div class="w-50">
                                        <i class="fa fa-star text-warning mr-1" style="font-size: 13px;" *ngFor="let i of [1,2,3,4,5];"></i>
                                    </div>   
                                    <div class="w-50 text-right">
                                        <span style="font-size: 14px;" class="font-weight-bold">Rs {{set_price}}</span><br/>
                                        <span class="text-grey">Inclusive all the Taxes</span>
                                    </div>
                                </div>
                                <div class="w-100 mt-3 text-grey text-capitalize" style="border-top: 1px solid lightgrey;">
                                    <div>
                                        <span class="text-grey" style="font-size: 13px;">Select Package</span><br/>
                                        <button class="mr-3 p-1 px-2 button-selected hprbtn"  (click)="price('7899',basic)" >Basic</button>
                                        <button class="mr-3 p-1 px-2  button-not-selected hprbtn"  (click)="price('19000',standard)" >standard</button>
                                    </div>
                                    <div class="my-3" style="font-size: 16px;">
                                        {{set_description}}
                                    </div>
                                </div>
                            </div>
                            <!--end of quote-->
                        </div>
                        <!--end rating and price-->

                    </div>
                    <!--end of main body of unlimited liability-->
                    <div class="w-30">
                        <!--start of v2 header of the image-->
                        <div class="mx-auto w-100 setafterimage">
                            <img src="assets/img/plc.png" class="w-100" />
                        </div>
                        <!--end of v2 header of the image-->
                        <!-- <div class="mx-auto w-65 side-images">
                            <div class="image-rotater">
                                <img src="assets/img/private-limited-asset.jpg" alt="Private limited company"  class="" >
                            </div>
                        </div>
                        <div class="w-50 image-container position-relative add-side-full-square" style="height: 300px;;top:8rem">
                            <div class="orange-dot position-absolute background-size-300" style="width:100px;height:100px;bottom:0px;right:-7rem"></div>
                            <div class="position-absolute" style="right: -15rem;bottom: -10rem;">
                                <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-2rem"></div>
                                <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!--end of header tag-->
            <div class="w-100 position-relative mt-5" style="z-index: 1000;margin-top:150px !important;">
                <div class="w-80 mx-auto box-shadow-1 bg-myntax white-dot" style="border-radius: 8px;min-height: 100px;">
                    <div class="display-flex">
                        <!--Total client served-->
                        <div class="w-25 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-white text-center">Total Clients</div>
                            <div class="text-white text-center font-weight-bold">5000+</div>
                        </div>
                        <!--Total client served-->
                        <div class="w-25 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-white text-center">Active Clients</div>
                            <div class="text-white text-center font-weight-bold">6000+</div>
                        </div>
                        <!--Total client served-->
                        <div class="w-25 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-white text-center">Compliances Filled</div>
                            <div class="text-white text-center font-weight-bold">7000+</div>
                        </div>
                        <!--Total client served-->
                        <div class="w-25 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-center text-white">LLP Registered</div>
                            <div class="text-center text-white font-weight-bold">8000+</div>
                        </div>
                    </div>
                </div>
                <div class="mx-auto max-width my-5">
                    <div class="display-flex w-100">
                        <!--image container-->
                        <div class="w-50">
                            <div class="p-2 pt-5 box-sizing-border-box">
                                <div class="mx-auto mt-5 text-center position-relative scrollSide">
                                    <img src="assets/img/llp-registration.png" height="400px" />
                                </div>
                            </div>
                        </div>
                        <!--text container-->
                        <div class="w-50 text-height">
                            <div class="w-100 text-gradient-myntax">
                                <h3 class="text-grey my-4 myntax-font">Private limited company </h3>
                            </div>
                            <!--llp registration -->
                            <div class="my-4 text-grey ">
                                <div class="">
                                    A Private Limited company is a type of privately held business venture. Private Limited company limits the liability of owners liability to the extent of their Shares capital contributed in the Company.Private Limited Company is Register under the Companies Act 2013 in the administration of Registerer of Companies which is a office under Ministry of Corporate Affairs
                                    <br/><br/>
                                    Private Limited Company is Most popular Corporate entity amongst, medium and large businesses in india due to Various Advantage. According to mca almost 90 to 95 percent of Company Register in India is Private limited Company.  
                                    <br/><br/>
                                    <li>Ideal for Startups &amp; Growing Business</li>
									<li>Easy to Get Funding, Raise Venture Capital Fund</li>
									<li>Has Limited Liability</li>
                                    <br/><br/>
                                    Private Limited Company require 1 lakh Capital to register under RoC and Minimum of 2 directors and Shareholder.     
                                </div>
                                <div class="text-center my-4">
                                    <button class="bg-secondary-myntax w-40 p-2 text-white" style="border-radius: 5px;border:0px;">Book Consultancy</button>
                                </div>
                            </div>
                            <!--end of llp registration-->
                        </div>
                    </div>
                </div>
                <!--end of about limited liability partnership-->
                <!--feature of limited liability partnership-->
                <div class="max-width mx-auto w-100">
                    <div class="w-100 text-gradient-myntax">
                        <h3 class="text-grey my-4 myntax-font text-center">Features of Private limited company Registration </h3>
                    </div>
                    <div class="mt-3 w-80 mx-auto why-after position-relative">
                        <!--list-->
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">Sepearate Legal Entity : </label>
                            <span class="text-grey" style="font-size: inherit;">The major benefit of the private limited company is that the Private limited company is a Juridical Person in the law which can buy or sell properties and shares , enter in to contract , sue or be sued upon it's own name. The death , insanity , incapacity or insolvency of it's shareholder or directors does not affect the existence of the Private limited Company in any way.</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">Limited Liability : </label>
                            <span class="text-grey" style="font-size: inherit;">Like a company, LLP also has a separate legal entity. So the partners and the limited liability partnership (LLP) in are distinct from each other. This is like a company where partners are different from the company.</span>
                        </div>
                        <!-- <div class="orange-dot position-absolute background-size-300" style="top:4rem;left:-7rem"></div> -->
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">Tax Advantage : </label>
                            <span class="text-grey" style="font-size: inherit;">As everyone wants to minimize his tax burden thus company as per the income tax act 1961 has another main benefit of incorporation towards taxation .Private Limited Companies enjoy tax advantages added on to the limited liability wherein the companies pay corporation tax on their taxable profit and tend to get excused from higher personal income tax rates. Instead of being a sole trader forming of a company leads to increase in tax deduction and allowances redeemable against profits.</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">Dual Relationship : </label>
                            <span class="text-grey" style="font-size: inherit;">In Private Limited company it is possible for a company to make a valid and effective contract with any of it's members or Shareholder or employee . It is also possible for a person to be in control of a company and at the same time be in its employment. Thus, a person can at the same time be a shareholder, creditor, director and also an employee of that Private limited company.</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">Easy Transferability of Shares: </label>
                            <span class="text-grey" style="font-size: inherit;">Shares of a Private Limited company limited by shares are transferable by a shareholder to any other person. The transfer is easy as compared to the transfer of interest in business run as a proprietary concern or a partnership. Filing and signing a share transfer form and handing over the buyer of the shares along with share certificate can easily transfer shares from one person to another..</span>
                        </div>
                        <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-3rem"></div>
                        <div class="circle-secondary position-absolute" style="width:40px;height:40px;bottom:55px;right:-1rem;z-index: 1;"></div>
                        <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
                        <!--end of list-->
                    </div>
                </div>
                <!--end of limited liability partnership-->
                <!--benefits of limited liability partnership-->
                <div class="mx-auto max-width my-5 pt-5">
                    <!--text-->
                    <div class="display-flex">
                        <div class="w-50">
                            <div class="w-100 text-gradient-myntax">
                                <h3 class="text-grey my-4 myntax-font text-center">Private Limited Company Compliance </h3>
                            </div>
                            <div class="p-2 box-sizing-border-box position-relative">
                                <!-- <div class="orange-dot position-absolute background-size-300" style="top:4rem;left:-8rem"></div> -->
                                <!--points starting from here-->
                                <div class="mt-3 w-100">
                                    <div class="text-grey">All companies registered in India are required to maintain compliance under various regulations. Failure to maintain compliance can lead to penalty or disqualification of the directors.s</div>
                                </div>
                                <div class="mt-3 w-100">
                                    <div class="text-grey">Myndtax can help you with accounting and maintaining of statutory compliances for the company at a very affordable price point for you private limited company.</div>
                                </div>
                                <div class="mt-3 w-100">
                                    <div class="text-grey">Some of the important compliances for companies registered in India are given to the right. Please note that additional forms will also be applicable as per government notification.</div>
                                </div>
                                <div class="mt-3 w-100">
                                    <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                                    <label class="text-secondary-myntax font-weight-bold">Statutory Auditor appointment</label>
                                </div>
                                <div class="w-100">
                                    <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                                    <label class="text-secondary-myntax font-weight-bold">Commencement of Business</label>
                                </div>
                                <div class="w-100">
                                    <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                                    <label class="text-secondary-myntax font-weight-bold">Income Tax Filing</label>
                                </div>
                                <div class="w-100">
                                    <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                                    <label class="text-secondary-myntax font-weight-bold">Annual Return</label>
                                </div>
                                <div class="w-100">
                                    <i class="fa fa-dot-circle-o text-secondary-myntax mr-3"></i>
                                    <label class="text-secondary-myntax font-weight-bold">DIN KYC</label>
                                </div>
                                <div class="circle-secondary position-absolute" style="width:40px;height:40px;bottom:55px;right:-1rem"></div>
                                <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-4rem;z-index:1;"></div>
                                <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem;z-index: 1;"></div>
                                <!--end of points here-->
                            </div>
                        </div>
                        <div class="w-50 pt-5 position-relative">
                            <div class="mt-5 text-center">
                                <img src="assets/img/benefits-of-llp.png" class="w-80"/>
                            </div>
                        </div>
                    </div>
                    <!--letter-->
                </div>
                <!--end of benefits of limited liability partnership-->
                <!--steps for Registration llp-->
                <div class="w-100 my-3 position-relative">
                    <div class="bg-myntax w-100 overflow-hidden trans-div position-relative" style="min-height: 600px;">
                        <div class="text-center max-width mx-auto py-5">
                            <div class="text-center text-white">
                                <h4>Private limited Company Registration Steps</h4>
                            </div>
                        </div>
                        <div class="max-width mx-auto w-100" style="margin-bottom: 180px;">
                            <!--first column-->
                            <div class="display-flex my-3 ml-5">
                                <div class="w-35 mr-5">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/pendrive.png" class="w-70 position-relative" style="top:10px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Apply for digital Signature</div>
                                    <div class="text-white w-80 text-justify">
                                        Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                                    </div>
                                </div>
                                <div class="w-35 mr-5">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/teacher.png" class="w-70 position-relative" style="top:10px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Apply for Director Identification Number</div>
                                    <div class="text-white w-80 text-justify">
                                        Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                                    </div>
                                </div>
                                <div class="w-35 mr-5">
                                    <!--image container-->
                                    <div class="w-180">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/identity.png" class="w-60 position-relative" style="top:18px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Apply for Private limited company  Name</div>
                                    <div class="text-white w-80 text-justify">
                                        Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                                    </div>
                                </div>
                            </div>
                            <!--end of first column-->
                            <!--first column-->
                            <div class="display-flex my-5 ml-5">
                                <div class="w-35 mr-5">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/preparation.png" class="w-60 position-relative" style="top:14px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Prepare Document</div>
                                    <div class="text-white w-80 text-justify">
                                        Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                                    </div>
                                </div>
                                <div class="w-35 mr-5">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/contract.png" class="w-60 position-relative" style="top:15px;left:3.5px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Prepare the agreement </div>
                                    <div class="text-white w-80 text-justify">
                                        Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                                    </div>
                                </div>
                                <div class="w-35 mr-5">
                                    <!--image container-->
                                    <div class="w-180">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/card.png" class="w-70 position-relative" style="top:18px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Apply for Pan Card</div>
                                    <div class="text-white w-80 text-justify">
                                        Apply for Digital Signature Certificate (DSC) It is mandatory to be a designated partner in LLP
                                    </div>
                                </div>
                            </div>
                            <!--end of first column-->
                        </div>
                    </div>
                    <!-- <div class="orange-dot position-absolute background-size-300" style="bottom: 4rem;left: 2rem;"></div> -->
                    <!-- <div class="orange-dot position-absolute background-size-300" style="top: -3rem;right: 2rem;"></div> -->
                    <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 9rem;"></div>
                    <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: 6rem;z-index: 1;"></div>
                    <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: 4rem;z-index: 1;"></div>
                </div>
                <!--end of steps for Registration llp-->
                <div class="mx-auto max-width w-100">
                    <div class="w-100 text-gradient-myntax">
                        <h3 class="text-grey my-4 myntax-font text-center">Private Limited Company Registration Cost </h3>
                    </div>
                    <div class="my-3 w-100">
                        <div class="mx-auto w-80">
                            <div class="display-flex ml-5 mx-auto position-relative llp-cost">
                                <!--pricing-->
                                <div class="w-50 position-relative">
                                    <div class="w-70 mx-auto light-box-shadow position-relative">
                                        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 0rem;z-index:2;"></div>
                                        <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: -3rem;z-index: 1;"></div>
                                        <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: -4rem;z-index: 1;"></div>
                                        <!-- <div class="orange-dot position-absolute background-size-300" style="bottom : 3rem;left: -8rem;"></div> -->
                                        <div class="w-100 p-4 box-sizing-border-box bg-secondary-myntax">
                                            <div class=" text-white font-weight-600 text-center" style="font-size: 22px;;">Basic</div>
                                            <div class="text-center text-white position-relative">
                                                <i class="fa fa-inr position-absolute" style="top:18px"></i><span class="font-weight-bold ml-3" style="font-size:40px">7899</span>
                                                <div class="text-center text-white">All exclusive fees</div>
                                            </div>
                                        </div>
                                        <div class="p-4 box-sizing-border-box">
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Digital Signature</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">2 Director identification Number</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Name Approval</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Authorized Capital Fee</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Incorporation Fee</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Stamp Duty</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">PAN and TAN</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">MOA and AOA preparation</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Current Account Opening</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Gst Registration</div>
                                            <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white" style="font-size: 15px;border:0px;border-radius: 5px;">Book Now</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="blue-dot position-absolute background-size-300" style="top : 3rem;right: -6rem;"></div>
                                <!-- <div class="orange-dot position-absolute background-size-300" style="bottom: 3rem;right: -6rem;"></div> -->
                                <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 5rem;z-index:2;"></div>
                                <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: 2rem;z-index: 1;"></div>
                                <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: 1rem;z-index: 1;"></div>
                                <!--pricing start for second one-->
                                <div class="w-50">
                                    <div class="w-70 mx-auto light-box-shadow">
                                        <div class="w-100 p-4 box-sizing-border-box bg-secondary-myntax">
                                            <div class=" text-white font-weight-600 text-center" style="font-size: 22px;;">Standard</div>
                                            <div class="text-center text-white position-relative">
                                                <i class="fa fa-inr position-absolute" style="top:18px"></i><span class="font-weight-bold ml-3" style="font-size:40px">19899</span>
                                                <div class="text-center text-white">All exclusive fees</div>
                                            </div>
                                        </div>
                                        <div class="p-4 box-sizing-border-box">
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Digital Signature</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">2 Director Identification Number</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Name Approval</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Authorized Capital Fee</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Incorporation fees</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Stamp Duty</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Pan & TAN</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Moa and AOA pr eparation</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Current Account Opening</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Commencement of Business</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Auditor Appointment Support</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Gst Registration</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">BookKeeping for 1 Year</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Financial Statement & Board Reports</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Bookkeeping</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Financial Statement Preparation</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">ITR-6 filing for Company</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Mca DIN eKYC for directors</div>   
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">MGT-7 & AOC-4 Filling</div> 
                                            <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white" style="font-size: 15px;border:0px;border-radius: 5px;">Book Now</button></div>  
                                        </div>
                                    </div>
                                </div>
                                <!--end of pricing of second one-->
                                <!--end of pricing-->
                            </div>
                        </div>
                    </div>
                </div>
                <!--document required-->
                <div class="mx-auto max-width my-5">
                    <div class="display-flex w-100">
                        <!--image container-->
                        <div class="w-50">
                            <div class="p-2 pt-5 box-sizing-border-box position-relative">
                                <div class="mx-auto text-center position-relative scrollSide">
                                    <img src="assets/img/llp-registration.png" height="400px" />
                                </div>
                                <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 5rem;z-index:2;"></div>
                                <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: 2rem;z-index: 1;"></div>
                                <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: 1rem;z-index: 1;"></div>
                                <div class="orange-dot position-absolute background-size-300" style="bottom : 22rem;left: -1rem;"></div>
                            </div>
                        </div>
                        <!--text container-->
                        <div class="w-50 text-height pt-4">
                            <div class="w-100 my-5 text-gradient-myntax">
                                <h3 class="text-grey my-4 myntax-font">Documents Required In Private limited Company Registration </h3>
                            </div>
                            <!--llp registration -->
                            <div class="my-4 text-grey ">
                                <div class="">
                                    <div class="my-4 ">The Law considers a company as a separate legal entity from its core founders. After a company gets Registered, it Will have company officers(directors) and Stakeholders (promoters and Subscribers). Each Individual in a private limited company gets regarded as the company's employee. The document Required to Register private limited Company Are as follows</div>
                                        <ul>
                                            <li>Pan Card of two directors</li>
                                            <li>Address Proof  of two Directors</li>
                                            <li>Aaddhar Card of two partners</li>
                                            <li>Utility bill of two partners (Electricity Bill,Bank passbook first page)</li>
                                            <li>Utility Bill of registered office</li>
                                            <li>If Registered office is rented NOC from landlord</li>
                                        </ul>
                                </div>
                                <div class="text-center my-4">
                                    <button class="bg-secondary-myntax w-40 p-2 text-white" style="border-radius: 5px;border:0px;">Book Consultancy</button>
                                </div>
                            </div>
                            <!--end of llp registration-->
                        </div>
                    </div>
                </div>
                <!--end of Document Required-->
                <!--start of frequently asked question-->
                <div class="mx-auto max-width my-5 pt-5">
                    <div class="w-100 text-center my-5 text-gradient-myntax">
                        <h3 class="text-grey my-4 myntax-font">Frequently Asked Question </h3>
                    </div>
                    <div class="w-80 mx-auto position-relative">
                        <!--start of frequently asked question div-->
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What is the minimum number of directors required to form a company ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    Minimum no. of directors for One Person Company: One, Private Limited Company: Two, for Public Limited Company: Three and, for producer company: Five.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What is the minimum number of Subscribers for Registering of a company ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    No. Any two or more persons associating for carrying on a lawful business with a view to profit may set up an LLP.
                                    <br/><br/>
                                    In the light of various inputs received by this Ministry for applicability of the LLP form to small entities and venture capital funded enterprises, it is proposed that the framework should not be restricted to professional services alone as was earlier recommended by Naresh Chandra Committee. Accordingly, the LLP Act does not restrict the benefit of LLP structure to certain classes of professionals only.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What are the qualifications for becoming a partner ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    <p>Any individual or body corporate may be a partner in a LLP. However an individual shall not be capable of becoming a partner of a LLP, if</p>
                                    <li>he has been found to be of unsound mind by a Court of competent jurisdiction and the finding is in force</li>
                                    <li>he is an undischarged insolvent; or</li>
                                    <li>he has applied to be adjudicated as an insolvent and his application is pending.</li>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    Minimum Capital Required To Register Private Limited company
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    1 lakh Capital is Required to Register the Private Limited Company.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What are the Compliances That have to filled every year ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    Invariably, the Balance Sheet and Annual Return have to be filed every year. Other documents such as, Return of Allotment (Form No. Pas-3), Change of Registered office (Form No. INC-22), Change among the Directors (Form No. DIR-12), Charges (Form No. CHG- 1, 9, 4) etc., have to be filed within the due date from the events taking place in the company as per the Companies Act, 2013.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    Where to Submit the 1 lakh Rupees for Registration of Private limited Company ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    You don't have to give 1 lakh Rupees To anyone You just Have to show the total Amount spent in setting up you Company at The end of Financial Year (at the end of first year)
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What is MoA and AoA ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    MoA is Memorandum of Association It outlines the nature of business entity. The memorandum includes the company's name, Registered Company address, the Company's aims and objectives, limited liability clause, share of capital, and other related Company Information
                                    <br/><br/>
                                    Aoa Stands for Article of Association , In essence, this document articulates the way the shares are distributed, voting rights of each class of stock, valuation of intellectual rights, activities of directors including appointments and meetings, management decisions, and many other intricate and internal processes of a company.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    Is a foreign entity allowed to be Director or shareholder of the private limited company ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    Yes, any foreign nationals, entity or an NRI can become a director or shareholder of a private limited company in India.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    Can Private Limited Company has FDI or Foreign direct investment ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    Yes, a private company is allowed to have foreign direct investment in their company.
                                </div>
                            </div>
                        </div>
                        <!--end of the frequently asked question-->
                        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 0rem;z-index:2;"></div>
                        <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: -3rem;z-index: 1;"></div>
                        <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: -4rem;z-index: 1;"></div>
                        <div class="orange-dot position-absolute background-size-300" style="bottom : 23rem;left: -7rem;"></div>
                    </div>
                </div>
                <!--end of frequently asked question-->
                <!--Customer review-->
                <div class="w-100 max-width mx-auto py-3">
                    <div class=" mb-5 font-weight-bold text-gradient-myntax text-center">
                        <h3 class="customer-satisfaction" style="font-size: 25px;">Our customer Satisfaction</h3>
                    </div>
                    <div class="mx-auto mt-5 w-80 position-relative">
                        <!-- start of customer review-->
                        <div class="w-100 display-flex mx-auto pt-5">
                            <!--start customer review box-->
                            <div class="w-35 light-box-shadow position-relative mr-5 px-4 pb-4 box-sizing-border-box" style="border-radius: 20px;" >
                                <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                                    <img src="assets/img/images.jpg"/>
                                </div>
                                <div class="text-dark mt-5 font-weight-bold text-center" style="font-size:14px">Anmol sinha</div>
                                <div class="w-100 text-center">
                                    <i class="fas fa-star mr-2 text-tertiary-myntax" *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
                                </div>
                                <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature</div>
                            </div>
                            <div class="w-35 light-box-shadow position-relative mr-5 px-4 pb-4 box-sizing-border-box" style="border-radius: 20px;" >
                                <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                                    <img src="assets/img/images.jpg"/>
                                </div>
                                <div class="text-dark mt-5 font-weight-bold text-center" style="font-size:14px">Anmol sinha</div>
                                <div class="w-100 text-center">
                                    <i class="fas fa-star mr-2 text-tertiary-myntax" *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
                                </div>
                                <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature</div>
                            </div>
                            <div class="w-35 light-box-shadow position-relative mr-5 px-4 pb-4 box-sizing-border-box" style="border-radius: 20px;" >
                                <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                                    <img src="assets/img/images.jpg"/>
                                </div>
                                <div class="text-dark mt-5 font-weight-bold text-center" style="font-size:14px">Anmol sinha</div>
                                <div class="w-100 text-center">
                                    <i class="fas fa-star mr-2 text-tertiary-myntax" *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
                                </div>
                                <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature</div>
                            </div>
                            <!--end of customer review box-->
                        </div>
                        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;left: -1rem;z-index:2;"></div>
                        <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;left: -4rem;z-index: 1;"></div>
                        <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;left: -5rem;z-index: 1;"></div>
                        <div class="orange-dot position-absolute background-size-300" style="bottom : 3rem;right: -7rem;"></div>
                    </div>
                </div>
                <!--end of customer review-->
                <div class="w-100 mx-auto">
                    <svg height="100%" width="100%" id="bg-svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 76.88995215311004,177.6937799043062 153.7799043062201,155.38755980861242 251,163 C 348.2200956937799,170.61244019138758 465.7703349282298,208.14354066985646 559,224 C 652.2296650717702,239.85645933014354 721.1387559808611,234.03827751196175 811,220 C 900.8612440191389,205.96172248803825 1011.6746411483255,183.70334928229664 1120,179 C 1228.3253588516745,174.29665071770336 1334.1626794258373,187.14832535885168 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150"></path><defs><linearGradient id="gradient"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 97.01435406698562,410.2200956937799 194.02870813397124,420.4401913875598 293,400 C 391.97129186602876,379.5598086124402 492.8995215311005,328.4593301435407 600,326 C 707.1004784688995,323.5406698564593 820.3732057416267,369.72248803827756 911,398 C 1001.6267942583733,426.27751196172244 1069.6076555023924,436.6507177033493 1154,434 C 1238.3923444976076,431.3492822966507 1339.1961722488038,415.67464114832535 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150"></path></svg>
                </div>
            </div>
    </div>
</div>
