import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-limited-company',
  templateUrl: './private-limited-company.component.html',
  styleUrls: ['./private-limited-company.component.css']
})
export class PrivateLimitedCompanyComponent implements OnInit {

  constructor() { }
  public set_price="7899";
  public basic='company registration with 2 Digital Signature, 2 DIN no., MOA and AOA , Name Approval, All related government fee & stamp duty , Pan , Tan , ESI &amp; PF registration , bank account Opening , Commencement of Business , GST registration ';
  public standard="company registration with 2 Digital Signature, 2 DIN no., MOA and AOA , Name Approval, All related government fee & stamp duty , Pan , Tan , ESI &amp; PF registration , bank account Opening , Commencement of Business , GST registration , book keeping for 1 year , financial statement and board Reports , bookkeeping , financial statement preparation, itr-6 filling for company and other service";
  public set_description=this.basic;

  

  ngOnInit(): void {
  }

  price(x: string,y: string){
    this.set_price=x;
    this.set_description=y;
  }
}
