import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { PrivateLimitedCompanyComponent } from './service/private-limited-company/private-limited-company.component';
import { LimitedLiabilityPartnershipComponent } from './service/limited-liability-partnership/limited-liability-partnership.component';
import { ProprietorshipComponent } from './proprietorship/proprietorship.component';
import { ColoredMenuComponent } from './colored-menu/colored-menu.component';
import { PartnershipComponent } from './service/partnership/partnership.component';
import { GstComponent } from './gst/gst.component';
import { OnePersonCompanyComponent } from './service/one-person-company/one-person-company.component';
import { PublicLimitedCompanyComponent } from './service/public-limited-company/public-limited-company.component';
import { NidhiCompanyComponent } from './service/nidhi-company/nidhi-company.component';
import { ProducerCompanyComponent } from './service/producer-company/producer-company.component';
import { SectionEightCompanyComponent } from './service/section-eight-company/section-eight-company.component';
import { MsmeRegistrationComponent } from './service/msme-registration/msme-registration.component';
import { DigitalSignatureComponent } from './service/digital-signature/digital-signature.component';
import { UdyogAdharComponent } from './service/udyog-adhar/udyog-adhar.component';
import { ImportExportCodeComponent } from './service/import-export-code/import-export-code.component';
import { FssaiRegistrationComponent } from './service/fssai-registration/fssai-registration.component';
import { TradeLiscenseComponent } from './service/trade-liscense/trade-liscense.component';
import { TradeMarkRegistrationComponent } from './service/trade-mark-registration/trade-mark-registration.component';
import { TradeMarkOppositionComponent } from './service/trade-mark-opposition/trade-mark-opposition.component';
import { TrademarkObjectionComponent } from './service/trademark-objection/trademark-objection.component';
import { TradeMarkRenewalComponent } from './service/trade-mark-renewal/trade-mark-renewal.component';
import { CopyRightRegistrationComponent } from './service/copy-right-registration/copy-right-registration.component';
import { DesignRegistrationComponent } from './service/design-registration/design-registration.component';
import { ProvisionalPatentComponent } from './service/provisional-patent/provisional-patent.component';
import { PatentRegistrationComponent } from './service/patent-registration/patent-registration.component';
import { GstRegistrationComponent } from './service/gst-registration/gst-registration.component';
import { GstInvoicingComponent } from './service/gst-invoicing/gst-invoicing.component';
import { GstAnnualReturnComponent } from './service/gst-annual-return/gst-annual-return.component';
import { GstLutRegistrationComponent } from './service/gst-lut-registration/gst-lut-registration.component';
import { TemporaryGstRegistrationComponent } from './service/temporary-gst-registration/temporary-gst-registration.component';
import { EwaybillRegistrationComponent } from './service/ewaybill-registration/ewaybill-registration.component';
import { InputTaxcreditComponent } from './service/input-taxcredit/input-taxcredit.component';
import { PropritorshipitComponent } from './service/propritorshipit/propritorshipit.component';
import { PrivateLimitedCompanyItComponent } from './service/private-limited-company-it/private-limited-company-it.component';
import { LlpItComponent } from './service/llp-it/llp-it.component';
import { ItrfillingComponent } from './service/itrfilling/itrfilling.component';
import { IncomeTaxNoticeComponent } from './service/income-tax-notice/income-tax-notice.component';
import { TdsreturnfillingComponent } from './service/tdsreturnfilling/tdsreturnfilling.component';
import { Form16generationComponent } from './service/form16generation/form16generation.component';
import { PayrolComponent } from './service/payrol/payrol.component';
import { PfregistrationComponent } from './service/pfregistration/pfregistration.component';
import { PfreturnfillingComponent } from './service/pfreturnfilling/pfreturnfilling.component';
import { EsiregistrationComponent } from './service/esiregistration/esiregistration.component';
import { AdddirectorsComponent } from './service/adddirectors/adddirectors.component';
import { RemovedirectorsComponent } from './service/removedirectors/removedirectors.component';
import { SharetransferComponent } from './service/sharetransfer/sharetransfer.component';
import { IncreaseAuthorizedcapitalComponent } from './service/increase-authorizedcapital/increase-authorizedcapital.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { GstreturnfillingComponent } from './service/gstreturnfilling/gstreturnfilling.component';
import { HeadingNavingationiComponent } from './heading-navingationi/heading-navingationi.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CheckoutComponent } from './service/checkout/checkout.component';
import { IncomeTaxModule } from './income-tax/income-tax.module';
import { FooterComponent } from './footer/footer.component';
import { StartupModule } from './startup/startup.module';
import { ComplianceModule } from './compliance/compliance.module';
import { GstServiceModule } from './gst-service/gst-service.module';
import { MoreServicesModule } from './more-services/more-services.module';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    PrivateLimitedCompanyComponent,
    LimitedLiabilityPartnershipComponent,
    ProprietorshipComponent,
    ColoredMenuComponent,
    PartnershipComponent,
    GstComponent,
    OnePersonCompanyComponent,
    PublicLimitedCompanyComponent,
    NidhiCompanyComponent,
    ProducerCompanyComponent,
    SectionEightCompanyComponent,
    MsmeRegistrationComponent,
    DigitalSignatureComponent,
    UdyogAdharComponent,
    ImportExportCodeComponent,
    FssaiRegistrationComponent,
    TradeLiscenseComponent,
    TradeMarkRegistrationComponent,
    TradeMarkOppositionComponent,
    TrademarkObjectionComponent,
    TradeMarkRenewalComponent,
    CopyRightRegistrationComponent,
    DesignRegistrationComponent,
    ProvisionalPatentComponent,
    PatentRegistrationComponent,
    GstRegistrationComponent,
    GstInvoicingComponent,
    GstAnnualReturnComponent,
    GstLutRegistrationComponent,
    TemporaryGstRegistrationComponent,
    EwaybillRegistrationComponent,
    InputTaxcreditComponent,
    PropritorshipitComponent,
    PrivateLimitedCompanyItComponent,
    LlpItComponent,
    ItrfillingComponent,
    IncomeTaxNoticeComponent,
    TdsreturnfillingComponent,
    Form16generationComponent,
    PayrolComponent,
    PfregistrationComponent,
    PfreturnfillingComponent,
    EsiregistrationComponent,
    AdddirectorsComponent,
    RemovedirectorsComponent,
    SharetransferComponent,
    IncreaseAuthorizedcapitalComponent,
    AboutusComponent,
    ContactusComponent,
    GstreturnfillingComponent,
    HeadingNavingationiComponent,
    CheckoutComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IncomeTaxModule,
    CommonModule,
    StartupModule,
    ComplianceModule,
    GstServiceModule,
    MoreServicesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
