import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { FssaiRegistrationComponent } from './fssai-registration/fssai-registration.component';
import { ImportExportCodeComponent } from './import-export-code/import-export-code.component';
import { LimitedLiabilityPartnershipComponent } from './limited-liability-partnership/limited-liability-partnership.component';
import { OnePersonCompanyComponent } from './one-person-company/one-person-company.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { PrivateLimitedCompanyComponent } from './private-limited-company/private-limited-company.component';
import { ProprietorshipComponent } from './proprietorship/proprietorship.component';
import { UdyamRegistrationComponent } from './udyam-registration/udyam-registration.component';

const routes: Routes = [
  { path: 'startup-proprietorship', component: ProprietorshipComponent },
  { path: 'startup-partnership', component: PartnershipComponent },
  { path: 'startup-one-person-company', component: OnePersonCompanyComponent },
  {
    path: 'startup-limited-liability-partnership',
    component: LimitedLiabilityPartnershipComponent,
  },
  {
    path: 'startup-private-limited-company',
    component: PrivateLimitedCompanyComponent,
  },
  {
    path: 'startup-digital-signature',
    component: DigitalSignatureComponent,
  },
  {
    path: 'startup-udyam-registration',
    component: UdyamRegistrationComponent,
  },
  {
    path: 'startup-import-export-code',
    component: ImportExportCodeComponent,
  },
  {
    path: 'startup-fssai-registration',
    component: FssaiRegistrationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StartupRoutingModule {}
