import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-limited-company-it',
  templateUrl: './private-limited-company-it.component.html',
  styleUrls: ['./private-limited-company-it.component.css']
})
export class PrivateLimitedCompanyItComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
