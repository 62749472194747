<footer class="footer-section mt-5 py-3">
  <div class="container">
    <div class="footer-cta mt-3 pb-3 ">
      <div class="row">
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="single-cta">
            <a href="#"><img src="../../assets/img/map-icon.png" class="cta-img" alt=""></a>
            <div class="cta-text">
              <h3 class="text-white">Address</h3>
              <span>Office no - 404, 4th floor, B zone, Near vijay sales, Pimpri, Pune, Maharashtra </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="single-cta">
            <a href="#"><img src="../../assets/img/telephone.png" class="cta-img" alt=""></a>
            <div class="cta-text">
              <h3 class="text-white">Contact Number </h3>
              <a href="tel:+917875662288">+91-7875662288 </a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="single-cta">
            <a href="#"><img src="../../assets/img/myndtax-email.png" class="cta-img" alt=""></a>
            <div class="cta-text">
              <h3 class="text-white">Email ID</h3>
              <a href="mailto:support@myndtax.in">support@myndtax.in</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-content py-5">
      <div class="row">
        <div class="col-lg-4">
          <div class="footer-widget">
            <div class="footer-logo">
              <a href="#"><img src="../../assets/img/myntax-transparent.png" class="img-fluid mb-2" alt=""></a>
            </div>
            <div class="footer-social-icons mt-4 mt-lg-2">
              <span class="d-block mb-3 font-weight-bold text-white">Follow Us</span>
              <a><img src="../../assets/img/facebook.png" alt="" class="social-img"></a>
              <!-- <a><img src="../../assets/img/instagram.png" alt="" class="social-img"></a>
              <a><img src="../../assets/img/linkedin.png" alt="" class="social-img"></a> -->
            </div>
            <!-- <div class="footer-text mt-4 mt-lg-2">
              <h3 class="text-white">About Us</h3>
              <p>We provide a curated set of quality services at an affordable price. Myndtax.com was launched in
                2012 with the simple mission of helping Entrepreneurs easily start and
                manage their business. From a small 3 member team in 2012 - while remaining bootstrapped and
                profitable, we grew to a 500+ strong team of employees. Today, we have the privilege of serving >
                100,000 loyal, small business customers and over 1.2 million platform users. In 2021-22, we raised
                funding from institutional investors to further our mission globally and serve more customers. The
                journey continues</p>
            </div> -->

          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="footer-widget">
            <div class="footer-widget-heading">
              <h3 class="text-white mt-4 mt-lg-0">Useful Links</h3>
            </div>
            <ul>
              <li><a routerLink="/">Home</a></li>
              <li><a routerLink="/about-us">About Us</a></li>
              <li><a routerLink="/contact-us">Contact us</a></li>
              <li><a routerLink="/documents">Our Services</a></li>


            </ul>
          </div>

        </div>
        <div class="col-md-6 col-lg-4 mt-5 mt-lg-0">
          <div class="footer-widget">
            <div class="footer-widget-heading">
              <h3 class="text-white">Subscribe</h3>
            </div>
            <!-- <div class="footer-text">
              <p>Dont miss to subscribe our latest news feed, Kindly fill the form below.</p>
            </div> -->
            <div class="subscribe-form">
              <form action="">
                <input type="email" name="email" id="email" placeholder="Email Address">
                <button><img src="../../assets/img/paper-plane.png" alt="" class="cta-img"></button>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-copyright mt-5 mt-lg-0">
    <div class="container">


      <div class="copyright-text text-center">
        <p>Copyright &copy; 2022, All Right Reserved <a href="#" class="text-white">MYNDTAX</a></p>
      </div>


    </div>
  </div>
</footer>
