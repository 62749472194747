import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IncomeTaxRoutingModule } from './income-tax-routing.module';
import { ProprietorShipComponent } from './proprietor-ship/proprietor-ship.component';
import { PrivateLimitedCompanyComponent } from './private-limited-company/private-limited-company.component';
import { LimitedLiabilityPartnershipComponent } from './limited-liability-partnership/limited-liability-partnership.component';
import { ItrFilingComponent } from './itr-filing/itr-filing.component';
import { IncomeTaxNoticeComponent } from './income-tax-notice/income-tax-notice.component';
import { TdsReturnFilingComponent } from './tds-return-filing/tds-return-filing.component';
import { Form16IssuanceComponent } from './form16-issuance/form16-issuance.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProprietorShipComponent,
    PrivateLimitedCompanyComponent,
    LimitedLiabilityPartnershipComponent,
    ItrFilingComponent,
    IncomeTaxNoticeComponent,
    TdsReturnFilingComponent,
    Form16IssuanceComponent,
  ],
  imports: [CommonModule, IncomeTaxRoutingModule, FormsModule],
})
export class IncomeTaxModule {}
