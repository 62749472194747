import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.css']
})
export class DigitalSignatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
