import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-llp-it',
  templateUrl: './llp-it.component.html',
  styleUrls: ['./llp-it.component.css']
})
export class LlpItComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
