import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form16generation',
  templateUrl: './form16generation.component.html',
  styleUrls: ['./form16generation.component.css']
})
export class Form16generationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
