<div class="home">
  <!-- <app-menu></app-menu> -->
  <div class="w-100">
    <div class=" home-top-page top-header position-relative " data-menu='true'>
      <div class="bg-myntax smile overflow-hidden position-relative" style="height:100%;">
        <!-- <div class="orange-dot position-absolute background-size-300" style="top:12.5rem;right:0px"></div> -->
        <div class="circle-dark-blue  position-absolute" style="bottom:-80px;right:-28px"></div>
        <div class="display-md-flex w-90 max-width padding-top mx-auto">
          <div class="w-md-50 w-100 pt-md-5 pt-3" style="z-index:100;">
            <div class="text-white mt-md-5 mt-2 myntax-font font-weight-bold" style="font-size: 30px;z-index: 100;">
              <!-- Lorem Ipsum is simply dummy text of the printing and typesetting industry -->
              <input type="text" placeholder="Search Your Service" class="w-100 px-4 py-2 text-grey"
                style="border-radius: 20px;border:1px solid transparent;font-size: 15px;z-index: 100;outline: none;" />
            </div>
            <div class="mt-5">
              <div class="display-flex">
                <div class="w-30 mr-5">
                  <div class="w-100">
                    <img src="assets/img/coin.png" class="w-90" />
                  </div>
                  <div class="text-white text-center">Tax Saving</div>
                </div>
                <div class="w-30 mr-5">
                  <div class="w-100">
                    <img src="assets/img/professional.png" class="w-85" style="margin-top:-13px" />
                  </div>
                  <div class="text-white texty-center">Expert Advice</div>
                </div>
                <div class="w-30">
                  <div class="w-100">
                    <img src="assets/img/phone.png" style="width:79%;margin-top:-9px" />
                  </div>
                  <div class="text-white text-center">Easily Accessible</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-md-50 w-100" style="z-index:100">
            <div class="w-100 text-center">
              <!--text start from here-->
              <div class="text-white text-center">
                <h2 class="font-weight-bold mt-6 font-size-29">We Are Solution To Every<br />Problem For Your Business
                </h2>
                <!-- <div class="w-md-65 w-  mx-auto  my-3">We are india's finest ca firm providing consultancy and service
                  on all your Business requirements whether bet it your tax, Itr, Audit or any thing else we are the
                  solution </div> -->
                <div class="w-70 mx-auto text-center mt-4">
                  <button type="button" style="border-radius:20px;border-color: transparent;z-index: 10"
                    class="w-50 bg-secondary-myntax text-white" data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop">Contact Sales
                  </button>
                </div>

              </div>
              <!--end of text here-->
              <!--form starts here-->
              <!-- <div class="w-60 mx-auto bg-white position-relative p-4" style="top: 100px;border-radius: 20px;">
                                <div class="font-weight-bold text-primary-myntax">Fill The Small Form To Get A Call From Us</div>
                                <div class="my-3">
                                    <input type="text" placeholder="Name" class="w-100 px-4 border-rounded box-sizing-border-box p-1" style="border:1px solid grey;border-radius: 10px;"/>
                                </div>
                                <div class="my-3">
                                    <input type="email" placeholder="Email" class="w-100 px-4 box-sizing-border-box p-1" style="border:1px solid grey;border-radius: 10px;"/>
                                </div>
                                <div class="my-3">
                                    <input type="tel" placeholder="Phone Number" class="w-100 onlyNumber px-4 box-sizing-border-box p-1" style="border:1px solid grey;border-radius: 10px;"/>
                                </div>
                                <div class="text-center">
                                    <button class="my-3 py-2 text-white bg-secondary-myntax w-100" style="border:1px solid transparent;border-radius: 10px;">Submit</button>
                                </div>
                            </div> -->
              <!--end fo form start here-->
              <!-- <img src="assets/img/men.png" class="flip"/> -->
            </div>
          </div>
        </div>
      </div>
      <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: 10px;right: 9rem;"></div>
      <div class="circle-primary position-absolute"
        style="width: 50px;height: 50px;bottom: -30px;right: 5.5rem;z-index: 1;"></div>
      <div class="circle-secondary position-absolute"
        style="width: 30px;height: 30px;bottom: 32px;right: 4rem;z-index: 1;"></div>
    </div>
    <!--start of partners-->
    <div class="w-md-100 w-90 mx-auto max-width mt-5 mt-md-0 position-relative">
      <!-- <div class="orange-dot position-absolute background-size-300" style="top:-12.5rem;"></div> -->
      <h3 class="text-grey mb-3 myntax-font">Partners</h3>
      <div class="w-100">
        <div class="w-100 row">
          <!--start of partners logo-->
          <div class="col-md-2 col-6">
            <div class="w-100 text-center">
              <img src="assets/img/mcdonalds.png" class="grayscale height-img-1" />
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="w-100 text-center">
              <img src="assets/img/tata.png" class="grayscale height-img-2" />
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="w-100 text-center">
              <img src="assets/img/srchout.png" class="grayscale height-img-3" style="margin-top:31px" />
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="w-100 text-center">
              <img src="assets/img/myntax.png" class="grayscale height-img-4" style="margin-top: 30px;" />
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="w-100 text-center">
              <img src="assets/img/fusec.png" class="grayscale" style="height:50px;margin-top: 30px;" />
            </div>
          </div>
          <div class="col-md-2 col-6">
            <div class="w-100 text-center">
              <img src="assets/img/fusec.png" class="grayscale" style="height:50px;margin-top: 30px;" />
            </div>
          </div>
          <!--end of partners logo-->
        </div>
      </div>
    </div>
    <!--end of partners-->
    <!--start of why us-->
    <div class="mt-5  w-100 mx-auto max-width overflow-hidden">
      <div class="w-100 mt-5 py-5 display-md-flex">
        <!--why us image entry-->
        <div class="w-md-50 w-100 text-center position-relative why-after">
          <!-- <div class="orange-dot position-absolute background-size-300" style="top:3.5rem;left:-2px"></div>
                    <div class="orange-dot position-absolute background-size-300" style="bottom:3.5rem;right:41px"></div> -->
          <img src="assets/img/whyus.png" class="w-80" />
        </div>
        <!--end of why us image entry-->
        <!--start of the why us content-->
        <div class="w-md-50 w-100 mt-5 p-3 box-sizing-border-box position-relative">
          <div class="w-100 text-gradient-myntax">
            <h3 class="text-grey my-4 myntax-font">About Myndtax </h3>
          </div>
          <div class="w-100 text-grey text-justify position-relative">
            We provide a curated set of quality services at an affordable price. Myndtax.com was launched in 2012 with
            the simple mission of helping Entrepreneurs easily start and manage their business. From a small 3 member
            team in 2012 - while remaining bootstrapped and profitable, we grew to a 500+ strong team of employees.
            Today, we have the privilege of serving > 100,000 loyal, small business customers and over 1.2 million
            platform users. In 2021-22, we raised funding from institutional investors to further our mission globally
            and serve more customers. The journey continues.

          </div>
          <div class="text-center my-5">
            <!-- <button class="">Book
              Consultancy</button> -->
            <button type="button" style="border-radius: 20px;border:0px;" class=" bg-myntax w-md-30 w-50 p-2 text-white"
              data-bs-toggle="modal" data-bs-target="#staticBackdrop">Book Consultancy
            </button>
          </div>
          <!-- BUTTON MODAL WHEN CLICKS -->
          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true" style="margin-top:60px">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">Book Consultancy </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form action="" method="post" #clientweb="ngForm" (ngSubmit)="onSubmitPostClient(clientweb.value)">
                    <div class="w-100 form-myntax mb-3">
                      <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100"
                        ngModel required #name="ngModel">
                    </div>
                    <div class="w-100 form-myntax mb-3">
                      <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel
                        required #phone="ngModel">
                    </div>
                    <div class="w-100 form-myntax mb-3">
                      <input type="email" name="email" class="w-100" placeholder="Email" ngModel required
                        #email="ngModel">
                    </div>
                    <div class="w-100 form-myntax mb-3">
                      <input type="text" name="service" class="w-100" placeholder="Service" ngModel required
                        #service="ngModel">
                    </div>
                    <div class="text-center my-4">
                      <button class="bg-secondary-myntax w-100 p-1 text-white btn"
                        style="border-radius: 5px;border:0px;" [disabled]="spin">
                        <span *ngIf="!spin">Book Consultancy</span>
                        <span *ngIf="spin"><i class="fas fa-redo"></i></span>
                      </button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>

          <!-- BUTTON MODAL ENDS -->
          <div class="circle-secondary position-absolute position-1a"
            style="width: 40px;height: 40px;bottom: -2px;z-index:2;"></div>
          <div class="circle-primary position-absolute postiion-1b"
            style="width: 50px;height: 50px;bottom: 17px;z-index: 1;"></div>
          <div class="circle-secondary position-absolute position-1c"
            style="width: 30px;height: 30px;bottom: -2px;z-index: 1;"></div>
        </div>
        <!--end of why us content-->
      </div>
    </div>
    <!--end of why us-->
    <!--start how were making financial easy-->
    <div class="w-100 my-5">
      <div class="w-100 box-sizing-border-box _1pdlt position-relative">
        <div class="w-100  display-md-flex">
          <div class="w-md-75 w-100 py-4 box-sizing-border-box height-3a">
            <div class="w-100 bg-light _1hroAl text-center overflow-hidden p-2 pt-3 box-sizing-border-box">
              <h1 style="font-size: 20px;" class="text-grey font-myntax">How we Changing Financial lives of Indians
              </h1>
              <div class="display-flex">
                <div class="w-md-75 w-100">
                  <div class="p-2 text-left text-grey _3hwcflo font-myntax">
                    <div class="mb-2 list-style position-relative">Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.</div>
                    <div class="mb-2 list-style position-relative">Lorem Ipsum has been the industry's standard .</div>
                    <div class="mb-2 list-style position-relative">when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book .</div>
                    <div class="mb-2 position-relative list-style">Lorem Ipsum has been the industry's standard .</div>
                  </div>
                  <div class="text-center my-2">
                    <button class="bg-myntax border-0 p-2 w-md-25 w-50 text-white" data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop" style="border-radius: 20px;">Book
                      Appointment</button>

                  </div>
                </div>
                <div class="w-25 d-md-block d-none position-relative">
                  <img src="assets/img/financial-advisor.png" width="100%" class="position-absolute"
                    style="top:-70px;left:-26px" />
                </div>
              </div>
            </div>
          </div>
          <div class="w-md-25 w-100 bg-myntax pb-4 pb-md-0  building _2algl">
            <div style="z-index: 3;" class="position-md-absolute">
              <div class="w-100 m text-center">
                <img src="assets/img/myntax-transparent.png" height="60px" class="pt-3" />
              </div>
              <div class="text-white p-4 box-sizing-border-box display-flex text-center">
                <div class="w-100">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                  classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
                  professor at Hampden-Sydney College in Virginia,
                </div>
              </div>
              <div class="text-center my-3">
                <button class="bg-white border-0 p-2 w-40 text-dark" style="border-radius: 20px;">Signup</button>
              </div>
            </div>
          </div>
        </div>
        <div class="circle-secondary position-absolute"
          style="width: 40px;height: 40px;bottom: -46px;right: 9rem;z-index:2;"></div>
        <div class="circle-primary position-absolute"
          style="width: 50px;height: 50px;bottom: -56px;right: 6rem;z-index: 1;"></div>
        <div class="circle-secondary position-absolute"
          style="width: 30px;height: 30px;bottom: -71px;right: 5rem;z-index: 1;"></div>
        <!-- <div class="orange-dot position-absolute background-size-300" style="bottom : -3rem;left: 2rem;"></div> -->
      </div>
    </div>
    <!--end how were making financial easy-->
    <!--start  our promise-->
    <div class="bg-myntax downsmile overflow-hidden position-relative mt-5" style="min-height:450px">
      <div style="margin-top: 136px; z-index: 3;" class="h-50 motion">
        <h1 class="w-80 mx-auto text-white text-center font-weight-bold my-3" style="font-size: 25px !important;">Our
          Promises</h1>
        <div class="display-md-flex w-90 mx-auto mt-4">
          <!--start of the box-->
          <div class="w-md-35 w-100 px-2 box-sizing-border-box" style="z-index: 1;">
            <div class="w-100 text-center">
              <div class="">
                <img src="assets/img/advisor.png" width="40%" />
              </div>
              <div class="my-3 text-white font-weight-bold w-70 text-center mx-auto">Contrary to popular belief, Lorem
                Ipsum is not</div>
            </div>
          </div>
          <div class="w-md-35 w-100 px-2 box-sizing-border-box">
            <div class="w-100 text-center">
              <div>
                <img src="assets/img/clock.png" width="40%" />
              </div>
              <div class="my-3 text-white font-weight-bold w-70 text-center mx-auto">Contrary to popular belief, Lorem
                Ipsum is not</div>
            </div>
          </div>
          <div class="w-md-35 w-100 px-2 box-sizing-border-box" style="z-index: 1;">
            <div class="w-100 text-center">
              <div>
                <img src="assets/img/relax.png" width="40%" />
              </div>
              <div class="my-3 text-white font-weight-bold w-70 text-center mx-auto">Contrary to popular belief, Lorem
                Ipsum is not</div>
            </div>
          </div>
          <!--end of the box-->
        </div>
      </div>
    </div>
    <!--end our promise-->
    <!--our service-->
    <div class="w-100 my-5 pb-5">
      <div class="max-width mx-auto w-100 position-relative">
        <div class="mx-auto text-center mb-5 font-weight-bold text-gradient-myntax">
          <h3 style="font-size: 25px;">Our services</h3>
        </div>
        <div class="display-md-flex w-90 mx-auto position-relative" style="z-index:2;">
          <!--first column-->
          <div class="w-md-35 w-100">
            <div class="w-90 mx-auto p-3 box-sizing-border-box light-box-shadow transition-1 cursor-pointer"
              style="min-height: 200px;">
              <h5 class="text-grey  mb-3">Proprietorship Registration</h5>
              <ul class="text-grey" style="font-size:14px;line-height: 2;">
                <li class="list-style-none">Aadhaar Card</li>
                <li class="list-style-none">Pan Card</li>
                <li class="list-style-none">Passport Size photograph</li>
                <li class="list-style-none">Electricity Bil/Utility Bill</li>
                <li class="list-style-none">Bank Passbook first page copy </li>
                <li class="list-style-none">Bank Passbook last page copy</li>
                <li class="list-style-none">Digital Signature (optional)</li>
              </ul>
              <div class="w-100 mt-3 text-center">
                <button class="text-white p-2 w-60 bg-myntax border-0" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" style="border-radius:20px;">Book
                  Consultancy</button>
              </div>
            </div>
            <div class="w-90 mx-auto mt-4 p-3 box-sizing-border-box light-box-shadow transition-1 cursor-pointer"
              style="min-height: 200px;">
              <h5 class="text-grey  mb-3">Gst Registration</h5>
              <ul class="text-grey" style="font-size:14px;line-height: 2;">
                <li class="list-style-none">Aadhaar Card</li>
                <li class="list-style-none">Pan Card</li>
                <li class="list-style-none">Passport Size photograph</li>
                <li class="list-style-none">Electricity Bil/Utility Bill</li>
                <li class="list-style-none">Bank Passbook first page copy </li>
                <li class="list-style-none">Bank Passbook last page copy</li>
                <li class="list-style-none">Digital Signature (optional)</li>
              </ul>
              <div class="w-100 mt-3 text-center">
                <button class="text-white p-2 w-60 bg-myntax border-0" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" style="border-radius:20px;">Book
                  Consultancy</button>
              </div>
            </div>
          </div>
          <!--end of first column-->
          <!--second column-->
          <div class="w-md-35 mt-4 mt-md-0 w-100">
            <div
              class="w-90 mx-auto p-3 box-sizing-border-box light-box-shadow transition-1 cursor-pointer border-radius-10"
              style="min-height: 200px;">
              <h5 class="text-grey  mb-3">Private limited Registration</h5>
              <ul class="text-grey" style="font-size:14px;line-height: 2;">
                <li class="list-style-none">Aadhaar Card</li>
                <li class="list-style-none">Pan Card</li>
                <li class="list-style-none">Passport Size photograph</li>
                <li class="list-style-none">Electricity Bil/Utility Bill</li>
                <li class="list-style-none">Bank Passbook first page copy </li>
                <li class="list-style-none">Bank Passbook last page copy</li>
                <li class="list-style-none">Digital Signature (optional)</li>
              </ul>
              <div class="w-100 mt-3 text-center">
                <button class="text-white p-2 w-60 bg-myntax border-0" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" style="border-radius:20px;">Book
                  Consultancy</button>
              </div>
            </div>
            <div
              class="w-90 mt-4 mx-auto p-3 box-sizing-border-box light-box-shadow transition-1 cursor-pointer border-radius-10"
              style="min-height: 200px;">
              <h5 class="text-grey  mb-3">LLP Registration</h5>
              <ul class="text-grey" style="font-size:14px;line-height: 2;">
                <li class="list-style-none">Aadhaar Card</li>
                <li class="list-style-none">Pan Card</li>
                <li class="list-style-none">Passport Size photograph</li>
                <li class="list-style-none">Electricity Bil/Utility Bill</li>
                <li class="list-style-none">Bank Passbook first page copy </li>
                <li class="list-style-none">Bank Passbook last page copy</li>
                <li class="list-style-none">Digital Signature (optional)</li>
              </ul>
              <div class="w-100 mt-3 text-center">
                <button class="text-white p-2 w-60 bg-myntax border-0" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" style="border-radius:20px;">Book
                  Consultancy</button>
              </div>
            </div>
          </div>
          <!--end of second column-->
          <!--third column-->
          <div class="w-md-35 mt-4 mt-md-0 w-100">
            <div class="w-90 mx-auto p-3 box-sizing-border-box light-box-shadow transition-1 border-radius-10"
              style="min-height: 200px;">
              <h5 class="text-grey  mb-3">Nidhi company Registration</h5>
              <ul class="text-grey" style="font-size:14px;line-height: 2;">
                <li class="list-style-none">Aadhaar Card</li>
                <li class="list-style-none">Pan Card</li>
                <li class="list-style-none">Passport Size photograph</li>
                <li class="list-style-none">Electricity Bil/Utility Bill</li>
                <li class="list-style-none">Bank Passbook first page copy </li>
                <li class="list-style-none">Bank Passbook last page copy</li>
                <li class="list-style-none">Digital Signature (optional)</li>
              </ul>
              <div class="w-100 mt-3 text-center">
                <button class="text-white p-2 w-60 bg-myntax border-0" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" style="border-radius:20px;">Book
                  Consultancy</button>
              </div>
            </div>
            <div class="w-90 mt-4 mx-auto p-3 box-sizing-border-box light-box-shadow transition-1 border-radius-10"
              style="min-height: 200px;">
              <h5 class="text-grey  mb-3">Partnership Registration</h5>
              <ul class="text-grey" style="font-size:14px;line-height: 2;">
                <li class="list-style-none">Aadhaar Card</li>
                <li class="list-style-none">Pan Card</li>
                <li class="list-style-none">Passport Size photograph</li>
                <li class="list-style-none">Electricity Bil/Utility Bill</li>
                <li class="list-style-none">Bank Passbook first page copy </li>
                <li class="list-style-none">Bank Passbook last page copy</li>
                <li class="list-style-none">Digital Signature (optional)</li>
              </ul>
              <div class="w-100 mt-3 text-center">
                <button class="text-white p-2 w-60 bg-myntax border-0" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" style="border-radius:20px;">Book
                  Consultancy</button>
              </div>
            </div>
          </div>
          <div class="circle-secondary position-absolute"
            style="width: 40px;height: 40px;top: -2rem;z-index: 2;left: -5rem;"></div>
          <div class="circle-primary position-absolute"
            style="width: 50px;height: 50px;top: -56px;left: -3rem;z-index: 1;"></div>
          <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;top: -71px;left: -0.5rem;">
          </div>
          <!-- <div class="orange-dot position-absolute background-size-300" style="top: -5rem;right: -6rem;"></div> -->
          <!--end of third column-->
        </div>
        <div class="circle-secondary position-absolute"
          style="width: 40px;height: 40px;bottom: -46px;right: 9rem;z-index:2;"></div>
        <div class="circle-primary position-absolute"
          style="width: 50px;height: 50px;bottom: -56px;right: 6rem;z-index: 1;"></div>
        <div class="circle-secondary position-absolute"
          style="width: 30px;height: 30px;bottom: -71px;right: 5rem;z-index: 1;"></div>
        <!-- <div class="orange-dot position-absolute background-size-300" style="bottom : -3rem;left: -2rem;"></div> -->
      </div>
    </div>
    <!--end our service-->
    <!--Quotes-->
    <div class="w-100">
      <div class="w-100 box-sizing-border-box py-5 mt-5">
        <div class="w-100 mx-auto bg-light overflow-hidden h-300">
          <!--position absolute-->
          <div class="position-absolute w-100">
            <img src="assets/img/office.jpg" alt="" class="w-100 h-300">
          </div>
          <!--position relative-->
          <div class="w-100 bg-myntax position-absolute h-300" style="opacity:0.7">
          </div>
          <div class="w-100 position-absolute h-300">
            <div class="max-width py-5 mx-auto w-80">
              <div class="row text-white mt-4">
                <div class="col-6 col-md-3 mx-auto">
                  <div class="font-size-15 font-weight-bold">
                    <h4>Founded in 2015</h4>
                    <div>
                      we are one of the best legal Consultancy in pan india we have been awarded my economist for our
                      outstanding work
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 mx-auto text-center text-white">
                  <img src="assets/img/rating.png" height="50px" />
                  <h3>8000</h3>
                  <h4>Happy Clients</h4>
                </div>
                <div class="col-6 col-md-3 mx-auto text-center text-white">
                  <img src="assets/img/rating.png" height="50px" />
                  <h3>8000</h3>
                  <h4>Startups Registered</h4>
                </div>
                <div class="col-6 col-md-3 mx-auto text-center text-white">
                  <img src="assets/img/rating.png" height="50px" />
                  <h3>8000</h3>
                  <h4>On Going Project</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Quotes-->
    <!--Our analysis-->
    <div class="my-5">
      <div class="display-md-flex w-100 pb-5">
        <!--start of left panel-->
        <div class="w-md-50 w-100 position-relative">
          <div class="text-center w-80 mx-auto overflow-hidden border-unusual light-box-shadow">
            <img src="assets/img/finance.jpg" width="100%" />
          </div>
          <div
            class="w-40 position-absolute d-none d-md-block border-unusual-1 p-2 box-sizing-border-box bg-white light-box-shadow"
            style="bottom:-36px;right:127px;height: 100px">
            <div class="w-90 display-flex mx-auto">
              <div class="w-35 text-center pt-2">
                <i class="text-primary-myntax far fa-user font-weight-bold" style="font-size: 18px;"></i>
                <div class="text-center text-grey" style="font-size: 12px;">quality</div>
                <i class="fa fa-dot-circle-o text-secondary-myntax mt-1 font-weight-bold" style="font-size: 16px;"></i>
              </div>
              <div class="w-35 text-center pt-2">
                <i class="text-primary-myntax far fa-building font-weight-bold" style="font-size: 18px;"></i>
                <div class="text-center text-grey" style="font-size: 12px;"> Affordable</div>
                <i class="fa fa-dot-circle-o text-secondary-myntax mt-1 font-weight-bold" style="font-size:16px;"></i>
              </div>
              <div class="w-35 text-center pt-2">
                <i class="text-primary-myntax fas fa-rupee-sign font-weight-bold" style="font-size: 18px;"></i>
                <div class="text-center text-grey" style="font-size: 12px;">Punctuality</div>
                <i class="fa fa-dot-circle-o text-secondary-myntax mt-1 font-weight-bold" style="font-size: 16px;"></i>
              </div>

            </div>
          </div>
          <div class="blue-dot position-absolute background-size-300" style="top : -3rem;left: 0rem;"></div>
        </div>
        <!--end of left pannel-->
        <div class="w-md-50 w-100 px-3 box-sizing-border-box">
          <div class=" mb-5 font-weight-bold text-gradient-myntax">
            <h3 style="font-size: 25px;">Our Vision</h3>
          </div>
          <div class="w-md-80 w-100 position-relative">
            <!--start of points-->
            <div class="text-points mb-3">
              <i class="fa fa-dot-circle-o text-secondary-myntax mr-3 "></i><label
                class="font-weight-bold text-secondary-myntax mb-0">Quality &amp; Reliability</label>
              <div class="text-grey" style="font-size:12px">Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literatur</div>
            </div>
            <div class="text-points mb-3">
              <i class="fa fa-dot-circle-o text-secondary-myntax mr-3 "></i><label
                class="font-weight-bold text-secondary-myntax mb-0">Quality &amp; Reliability</label>
              <div class="text-grey" style="font-size:12px">Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literatur</div>
            </div>
            <div class="text-points mb-3">
              <i class="fa fa-dot-circle-o text-secondary-myntax mr-3 "></i><label
                class="font-weight-bold text-secondary-myntax mb-0">Quality &amp; Reliability</label>
              <div class="text-grey" style="font-size:12px">Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literatur</div>
            </div>
            <div class="text-center mt-4">
              <button class="bg-myntax border-0 p-2 w-50 text-white" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop" style="border-radius: 20px;">Book
                Appointment</button>
            </div>
            <div class="circle-secondary position-absolute"
              style="width: 40px;height: 40px;bottom: -46px;right: 9rem;z-index:2;"></div>
            <div class="circle-primary position-absolute"
              style="width: 50px;height: 50px;bottom: -56px;right: 6rem;z-index: 1;"></div>
            <div class="circle-secondary position-absolute"
              style="width: 30px;height: 30px;bottom: -71px;right: 5rem;z-index: 1;"></div>
            <!--end of points-->
          </div>
        </div>
      </div>
    </div>
    <!--End of our analysis-->
    <!--Customer review-->
    <div class="w-100 max-width mx-auto py-3">
      <div class=" mb-5 font-weight-bold text-gradient-myntax text-center">
        <h3 class="customer-satisfaction" style="font-size: 25px;">Our customer Satisfaction</h3>
      </div>
      <div class="mx-auto mt-5 w-80">
        <!-- start of customer review-->
        <div class="w-100 display-md-flex mx-auto pt-5">
          <!--start customer review box-->
          <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box"
            style="border-radius: 20px;">
            <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
              <img src="../../assets/img/ramesh.jpeg" />
            </div>
            <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Ramesh Arolkar
            </div>
            <div class="w-100 text-center">
              <i class="fas fa-star mr-2 text-tertiary-myntax"
                *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
            </div>
            <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Very good service
              and good Co-ordination. Very much satisfied.</div>
          </div>
          <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box"
            style="border-radius: 20px;">
            <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
              <img src="../../assets/img/venugopal.jpeg" />
            </div>
            <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Venugopal
              Pillai
            </div>
            <div class="w-100 text-center">
              <i class="fas fa-star mr-2 text-tertiary-myntax"
                *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
            </div>
            <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Very Easy and like
              the complete ownership take by your team. Thank you very much.</div>
          </div>
          <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box"
            style="border-radius: 20px;">
            <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
              <img src="../../assets/img/nitin.jpeg" />
            </div>
            <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Nitin Kadam
            </div>
            <div class="w-100 text-center">
              <i class="fas fa-star mr-2 text-tertiary-myntax"
                *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
            </div>
            <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Really appreciate
              your timely service, which helps me a lot. Thank you very much.</div>
          </div>
          <!--end of customer review box-->
        </div>
      </div>
    </div>

    <!-- <div class="position-relative">
      <div class="w-100 mx-auto">
 
        <svg height="100%" width="100%" id="bg-svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg"
          class="transition duration-300 ease-in-out delay-150">
          <defs>
            <linearGradient id="gradient">
              <stop offset="5%" stop-color="#002bdc88"></stop>
              <stop offset="95%" stop-color="#32ded488"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 0,600 C 0,600 0,200 0,200 C 76.88995215311004,177.6937799043062 153.7799043062201,155.38755980861242 251,163 C 348.2200956937799,170.61244019138758 465.7703349282298,208.14354066985646 559,224 C 652.2296650717702,239.85645933014354 721.1387559808611,234.03827751196175 811,220 C 900.8612440191389,205.96172248803825 1011.6746411483255,183.70334928229664 1120,179 C 1228.3253588516745,174.29665071770336 1334.1626794258373,187.14832535885168 1440,200 C 1440,200 1440,600 1440,600 Z"
            stroke="none" stroke-width="0" fill="url(#gradient)"
            class="transition-all duration-300 ease-in-out delay-150"></path>
          <defs>
            <linearGradient id="gradient">
              <stop offset="5%" stop-color="#002bdcff"></stop>
              <stop offset="95%" stop-color="#32ded4ff"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 0,600 C 0,600 0,400 0,400 C 97.01435406698562,410.2200956937799 194.02870813397124,420.4401913875598 293,400 C 391.97129186602876,379.5598086124402 492.8995215311005,328.4593301435407 600,326 C 707.1004784688995,323.5406698564593 820.3732057416267,369.72248803827756 911,398 C 1001.6267942583733,426.27751196172244 1069.6076555023924,436.6507177033493 1154,434 C 1238.3923444976076,431.3492822966507 1339.1961722488038,415.67464114832535 1440,400 C 1440,400 1440,600 1440,600 Z"
            stroke="none" stroke-width="0" fill="url(#gradient)"
            class="transition-all duration-300 ease-in-out delay-150"></path>
        </svg>
      </div> -->


    <!-- FOOTER STARTS -->
    <!-- <div class="footer-section mt-5 position-absolute bottom-0 left-0">
        <div class=" mb-5 font-weight-bold d-flex gap-2 justify-content-center ">
          <div class="contact-us px-4 d-flex flex-column">
            <h3 class="customer-satisfaction text-center text-white" style="font-size: 25px;">Contact Us</h3>
            <div class="address ">
              <address>Address -
                <p class="text-white "> Office no - 404, 4th floor, B zone, Near vijay sales, Pimpri,
                  Pune,
                  Maharashtra</p>
              </address>
              <p>Contact No. - <a href="tel:7875662288" class="text-white">7875662288</a></p>
              <p>Email ID -<a href="mailto:support@myndtax.in" class="text-white"> Support@myndtax.in</a></p>
            </div>
          </div>
          <div class="about-us w-50 text-start ">
            <h3 class="customer-satisfaction text-center text-white" style="font-size: 25px;">About Us</h3>
            <p>We provide a curated set of quality services at an affordable price.</p>
            <p class="font-weight-normal">Myndtax.com was launched in 2012 with the simple mission of helping
              Entrepreneurs easily start and
              manage their business. From a small 3 member team in 2012 - while remaining bootstrapped and profitable,
              we
              grew to a 500+ strong team of employees. Today, we have the privilege of serving > 100,000 loyal, small
              business customers and over 1.2 million platform users. In 2021-22, we raised funding from institutional
              investors to further our mission globally and serve more customers. The journey continues</p>
          </div>
        </div> -->

    <!-- TERMS AND CONDITIONS IN FOOTER -->
    <!-- <div class="tandc mt-5 px-5 customer-satisfaction font-weight-bold text-gradient-myntax">
          <h3 class=" text-center" style="font-size: 25px;">
            TERMS & CONDITIONS</h3>
          <p class="font-weight-normal ">Welcome to Myndtax.com . This website is owned and operated by VERVE
            Financial
            Services Private Limited, 6
            Dhanammal Street, Spurtank Road, Chetpet, Chennai 600 031 INDIA. By visiting our website and accessing the
            information, resources, services, products, and tools we provide, you understand and agree to accept and
            adhere to the following terms and conditions as stated in this policy (hereafter referred to as 'User
            Agreement'), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy
            Policy section below for more information).
            We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree
            that it is your responsibility to review this User Agreement periodically to familiarize yourself with any
            modifications. Your continued use of this site after such modifications will constitute acknowledgment and
            agreement of the modified terms and conditions.
          </p>
        </div> -->

    <!-- PRIVACY POLICY -->
    <!-- <div class="privacy-policy mt-5 px-5 customer-satisfaction font-weight-bold text-gradient-myntax">
          <h3 class="text-center" style="font-size: 25px;">PRIVACY POLICY</h3>
          <div class="font-weight-normal">
            <p class="mt-2">This Privacy Policy governs the manner in
              which Myndtax.com collects, uses,
              maintains and discloses information collected from users (each, a "User") of the http://www.Myndtax.com
              website ("Site"). This privacy policy applies to the Site and all products and services offered by Myndtax.
            </p>
            <div class="policy-lists">
              <ol>
                <li class="font-weight-bold">PERSONAL IDENTIFICATION INFORMATION</li>
                <p>We may collect personal identification information from Users in a variety of ways, including, but not
                  limited to, when Users visit our site, register on the site, place an order, and in connection with
                  other activities, services, features or resources we make available on our Site. Users may be asked for,
                  as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site
                  anonymously. We will collect personal identification information from Users only if they voluntarily
                  submit such information to us. Users can always refuse to supply personally identification information,
                  except that it may prevent them from engaging in certain Site related activities.
                </p>
                <li class="font-weight-bold">NON-PERSONAL IDENTIFICATION INFORMATION</li>
                <p>We may collect non-personal identification information about Users whenever they interact with our
                  Site. Non-personal identification information may include the browser name, the type of computer and
                  technical information about Users means of connection to our Site, such as the operating system and the
                  Internet service providers' utilized and other similar information.
                </p>
                <li class="font-weight-bold">WEB BROWSER COOKIES</li>
                <p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard
                  drive for record-keeping purposes and sometimes to track information about them. User may choose to set
                  their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note
                  that some parts of the Site may not function properly.</p>
              </ol>
            </div>
          </div>
        </div> -->


    <!-- </div> -->
    <!-- END OF FOOTER SECTION -->

    <!-- </div> -->


  </div>
</div>
