import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fssai-registration',
  templateUrl: './fssai-registration.component.html',
  styleUrls: ['./fssai-registration.component.css']
})
export class FssaiRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
