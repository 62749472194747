import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MoreServicesRoutingModule } from './more-services-routing.module';
import { FormsModule } from '@angular/forms';
import { AboutUsComponent } from './about-us/about-us.component';
import { DocumentsComponent } from './documents/documents.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

@NgModule({
  declarations: [AboutUsComponent, DocumentsComponent, ContactUsComponent],
  imports: [CommonModule, MoreServicesRoutingModule, FormsModule],
})
export class MoreServicesModule {}
