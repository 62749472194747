import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRoutesService } from 'src/app/api-routes.service';
import { packagePost } from 'src/app/class/heading';

@Component({
  selector: 'app-itrfilling',
  templateUrl: './itrfilling.component.html',
  styleUrls: ['./itrfilling.component.css']
})
export class ItrfillingComponent implements OnInit {

  constructor(public _ApiServiceService: ApiRoutesService,private router: Router) { }
  public set_price="988";
  public basic='Income tax return filing for an individual with salary income. ITR-1 return';
  public standard="Income tax return filing for individuals having NRI income, directorship, shareholding in private company or more than 2 house property. ITR-2 return.";
  public set_description=this.basic;

  

  ngOnInit(): void {
  }

  price(x: string,y: string){
    this.set_price=x;
    this.set_description=y;
  }
  spin = false;

  onSubmitPostClient(data: any) {    
    this.spin = true;

    var opost = new packagePost();
    opost.name = data.name;
    opost.phone = data.phone;
    opost.package_name = "ITR Filling";
    opost.email = data.email;
    opost.package_price = this.set_price;

    this._ApiServiceService.postClientData(opost).subscribe(
      (res) => {
          this.spin = false;
          localStorage.setItem('payObject',JSON.stringify(
            {client_id:res.client_id,
              service_id:res.service_id,
              name:opost.name,
              amount:opost.package_price,
              email:data.email,
              phone:data.phone
            }));
          alert(res.message);
          this.router.navigate(['/checkout'])
          .then(() => {
            window.location.reload();
          });
      }
    );
  }
}
