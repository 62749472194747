import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gst-lut-registration',
  templateUrl: './gst-lut-registration.component.html',
  styleUrls: ['./gst-lut-registration.component.css']
})
export class GstLutRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
