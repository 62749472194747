<!-- <app-colored-menu></app-colored-menu> -->
<!-- <div class="w-100 pt-2 text-grey box-sizing-border-box bg-white font-size-14 box-shadow-1 d-none d-md-block"
  style="position: fixed;top:50px;z-index:10001;">
  <div class="w-90 mx-auto pb-2 px-3  display-flex">
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Overview</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">About Proprietorship</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Compliances</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Steps to Register</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Price Of Registration</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Documents Required</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">FAQ</div>
    <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Our Customer Satisfaction</div>
  </div>
  <div class="w-100">
    <div class="bg-secondary-myntax set-loader" style="border-radius: 10px;height: 5px;width:0%;"></div>
  </div>
</div> -->
<div class="set-max-100 position-relative">
  <div class="top-header" data-menu='false' style="padding-top: 60px;"></div>
  <div class="">
    <!--dextop view header-->
    <div class="mx-auto d-md-block d-none max-width">
      <div class="display-flex display-md- mt-5">
        <!--main body of limited liability partner ship-->
        <div class="w-70 position-relative">
          <div class="text-gradient-myntax">
            <h1 class="font-weight-bold" style="font-size:30px">Proprietorship</h1>
          </div>
          <!--rating and price-->
          <div class="w-100 display-flex">
            <!--form start here-->
            <div class="w-40 pr-5 box-sizing-border-box position-relative">
              <div class="mt-4 w-100">
                <form action="" method="post" #client="ngForm" (ngSubmit)="onSubmitPostClient(client.value)">
                  <div class="w-100 form-myntax mb-3">
                    <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100"
                      ngModel required #name="ngModel">
                  </div>
                  <div class="w-100 form-myntax mb-3">
                    <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel required
                      #phone="ngModel">
                  </div>
                  <div class="w-100 form-myntax mb-3">
                    <input type="email" name="email" class="w-100" placeholder="Email" ngModel required
                      #email="ngModel">
                  </div>
                  <div class="text-center my-4">
                    <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;"
                      [disabled]="spin">
                      <span *ngIf="!spin">Book Consultancy</span>
                      <span *ngIf="spin"><i class="fas fa-redo"></i></span>
                    </button>
                  </div>
                </form>
              </div>
              <div class="circle-secondary position-absolute"
                style="width: 40px;height: 40px;bottom: -102px;right: 9rem;"></div>
              <div class="circle-primary position-absolute"
                style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
              <div class="circle-secondary position-absolute"
                style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
            </div>
            <!--end of form is here-->
            <!--start of quote-->
            <div class="w-60 px-3 box-sizing-border-box">
              <div class="w-100 mt-3 text-grey" style="border-top: 1px solid lightgrey;">

                <div class="my-3" style="font-size: 16px;">
                  Sole Proprietorship Firm Registration is single person firm registration is the main objective is open
                  current bank account on the name of business so it's a simply firm which is enable doing business in
                  India with less compliance and less costing. A Sole Proprietorship Firm require minimum 2 legal entity
                  proof for open a current bank account.
                </div>
              </div>
            </div>
            <!--end of quote-->
          </div>
          <!--end rating and price-->

        </div>
        <!--end of main body of unlimited liability-->
        <div class="w-30">
          <!--start of v2 header of the image-->
          <div class="mx-auto w-100 setafterimage">
            <img src="assets/img/plc.png" class="w-100" />
          </div>
          <!--end of v2 header of the image-->
          <!-- <div class="mx-auto w-65 side-images">
                            <div class="image-rotater">
                                <img src="assets/img/private-limited-asset.jpg" alt="Private limited company"  class="" >
                            </div>
                        </div>
                        <div class="w-50 image-container position-relative add-side-full-square" style="height: 300px;;top:8rem">
                            <div class="grey-dot position-absolute" style="width:100px;height:100px;bottom:0px;right:-7rem"></div>
                            <div class="position-absolute" style="right: -15rem;bottom: -10rem;">
                                <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-2rem"></div>
                                <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
                            </div>
                        </div> -->
        </div>
      </div>
    </div>
    <!--end of dextop view header-->
    <!--mobile version header-->
    <div class="w-100">
      <div class=" w-100 p-2 d-md-none d-block box-sizing-border-box">
        <!--img setting-->
        <div class="w-100">
          <img src="../../../assets/img/gst-registration-m.webp" width="100%" class="border-rounded" alt="">
        </div>
        <div class="text-gradient-myntax mt-4 pl-3 pl-md-0 ">
          <h1 class="font-weight-bold" style="font-size:30px">Proprietorship</h1>
        </div>
        <!--description-->
        <div class="text-justify w-100 mx-auto">
          <div class="w-100 px-3 box-sizing-border-box">
            <div class="w-100 mt-3 text-grey " style="border-top: 1px solid lightgrey;">

              <div class="my-3" style="font-size: 16px;">
                Sole Proprietorship Firm Registration is Single Person Firm Registration is the main objective is Open
                Current Bank Account on the Name of Business so it's a simply firm which is enable doing business in
                India with Less Compliance and Less Costing. a Sole Proprietorship Firm require minimum 2 Legal Entity
                Proof for open a Current Bank Account.
              </div>
            </div>
          </div>
        </div>
        <!--end of description-->
        <!--forms-->
        <div class="w-90 mx-auto box-sizing-border-box position-relative">
          <div class="mt-4 w-100">
            <form action="" method="post" #clientPhone="ngForm" (ngSubmit)="onSubmitPostClient(clientPhone.value)">
              <div class="w-100 form-myntax mb-3">
                <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100" ngModel
                  required #name="ngModel">
              </div>
              <div class="w-100 form-myntax mb-3">
                <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel required
                  #phone="ngModel">
              </div>
              <div class="w-100 form-myntax mb-3">
                <input type="email" name="email" class="w-100" placeholder="Email" ngModel required #email="ngModel">
              </div>
              <div class="text-center my-4">
                <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;">Book
                  Consultancy</button>

              </div>
            </form>
          </div>
          <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -102px;right: 9rem;">
          </div>
          <div class="circle-primary position-absolute"
            style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
          <div class="circle-secondary position-absolute"
            style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
        </div>
        <!--end of forms-->
      </div>
    </div>
    <!--end of mobile version header-->
    <!--end of header tag-->
    <div class="w-100 position-relative mt-5" style="z-index: 1000;margin-top:150px !important;">
      <div class="w-md-80 w-100 mx-auto box-shadow-1 bg-myntax white-dot gst-reg-1a">
        <div class="row">
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-white text-center">Total Clients</div>
            <div class="text-white text-center font-weight-bold">5000+</div>
          </div>
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-white text-center">Active Clients</div>
            <div class="text-white text-center font-weight-bold">6000+</div>
          </div>
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-white text-center">Compliances Filled</div>
            <div class="text-white text-center font-weight-bold">7000+</div>
          </div>
          <!--Total client served-->
          <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
            <div class="w-80 mx-auto text-center">
              <img src="assets/img/user.png" height="70px" />
            </div>
            <div class="text-center text-white">LLP Registered</div>
            <div class="text-center text-white font-weight-bold">8000+</div>
          </div>
        </div>
      </div>
      <div class="mx-auto max-width my-5 py-5 top1">
        <div class="display-md-flex display-block w-md-100 w-90 mx-auto">
          <!--image container-->
          <div class="w-md-50 w-100">
            <div class="p-2  box-sizing-border-box">
              <div class="mx-auto d-none d-md-block text-center position-relative scrollSide">
                <img src="assets/img/llp-registration.png" height="400px" />
              </div>
              <!--mobile version-->
              <div class="w-100 d-md-none">
                <img src="../../../assets/img/gst-registration.jpg" style="width:100% !important;border-radius:10px;"
                  alt="">
              </div>
              <!--end of mobile version-->
            </div>
          </div>
          <!--text container-->
          <div class="w-md-50 w-100 text-height">
            <div class="w-100 text-gradient-myntax">
              <h3 class="text-grey my-4 myntax-font">Proprietorship </h3>
            </div>
            <!--llp registration -->
            <div class="my-4 text-grey ">
              <div>
                Sole Proprietorship Firm Registration is single person firm registration is the main objective is open
                current bank account on the name of business so it's a simply firm which is enable doing business in
                India with less compliance and less costing. A Sole Proprietorship Firm require minimum 2 legal entity
                proof for open a current bank account.
                <br>
                Proprietorship is easiest Business Structure to start in India. A proprietorship is a type of business
                that is owned, managed, and controlled by one person. As the proprietorship and proprietor are one and
                the same, it is very easy to start and there are very minimal compliance requirements.
                As the proprietor and the business are one and the same, a proprietorship cannot have other partners or
                shareholders.

              </div>
              <div class="text-center my-4">
                <button class="bg-secondary-myntax w-md-40 w-60 p-2 text-white  btn-consultancy"
                  style="border-radius: 20px;border:0px;">Book Consultancy</button>
                <button class="bg-secondary-myntax w-md-40 w-60 p-2 text-white  btn-mobile"
                  style="border-radius: 20px;border:0px;">Book Consultancy</button>
              </div>
            </div>
            <!--end of llp registration-->
          </div>
        </div>
      </div>
      <!--end of about limited liability partnership-->






    </div>
  </div>
</div>
