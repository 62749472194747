import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-mark-renewal',
  templateUrl: './trade-mark-renewal.component.html',
  styleUrls: ['./trade-mark-renewal.component.css']
})
export class TradeMarkRenewalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
