import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temporary-gst-registration',
  templateUrl: './temporary-gst-registration.component.html',
  styleUrls: ['./temporary-gst-registration.component.css']
})
export class TemporaryGstRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
