import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pfreturnfilling',
  templateUrl: './pfreturnfilling.component.html',
  styleUrls: ['./pfreturnfilling.component.css']
})
export class PfreturnfillingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
