import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gst-invoicing',
  templateUrl: './gst-invoicing.component.html',
  styleUrls: ['./gst-invoicing.component.css']
})
export class GstInvoicingComponent implements OnInit {

  constructor() { }
  public set_price="988";
  public basic='Completely managed GST registration service until obtaining GSTIN with Expert Support and 1 year LEDGERS accounting software. Inclusive of all government fee and taxes. ';
  public standard="Completely managed GST registration service and GST return filing service for 3 months with Expert Support and 1 year LEDGERS accounting software. Inclusive of all government fee and taxes.";
  public set_description=this.basic;

  

  ngOnInit(): void {
  }

  price(x: string,y: string){
    this.set_price=x;
    this.set_description=y;
  }

}
