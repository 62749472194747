import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdddirectorsComponent } from '../service/adddirectors/adddirectors.component';
import { AddDirectorsComponent } from './add-directors/add-directors.component';
import { IncreaseAuthorizedCapitalComponent } from './increase-authorized-capital/increase-authorized-capital.component';
import { RegistrationOfficeChangeComponent } from './registration-office-change/registration-office-change.component';
import { RemoveDirectorsComponent } from './remove-directors/remove-directors.component';
import { ShareTransferComponent } from './share-transfer/share-transfer.component';

const routes: Routes = [
  {
    path: 'registration-office-change',
    component: RegistrationOfficeChangeComponent,
  },
  {
    path: 'add-directors',
    component: AddDirectorsComponent,
  },
  {
    path: 'remove-directors',
    component: RemoveDirectorsComponent,
  },
  {
    path: 'share-transfer',
    component: ShareTransferComponent,
  },
  {
    path: 'increase-authorized-capital',
    component: IncreaseAuthorizedCapitalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComplianceRoutingModule {}
