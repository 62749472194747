import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GstServiceRoutingModule } from './gst-service-routing.module';
import { FormsModule } from '@angular/forms';
import { GstAnnualReturnNewComponent } from './gst-annual-return-new/gst-annual-return-new.component';
import { GstLutFillingComponent } from './gst-lut-filling/gst-lut-filling.component';
import { TempGstNumberComponent } from './temp-gst-number/temp-gst-number.component';
import { GstRegistrationComponent } from './gst-registration/gst-registration.component';
import { GstReturnFillingComponent } from './gst-return-filling/gst-return-filling.component';

@NgModule({
  declarations: [GstAnnualReturnNewComponent, GstLutFillingComponent, TempGstNumberComponent, GstRegistrationComponent, GstReturnFillingComponent],
  imports: [CommonModule, GstServiceRoutingModule, FormsModule],
})
export class GstServiceModule {}
