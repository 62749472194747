import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading-navingationi',
  templateUrl: './heading-navingationi.component.html',
  styleUrls: ['./heading-navingationi.component.css']
})
export class HeadingNavingationiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
