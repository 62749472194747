import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pfregistration',
  templateUrl: './pfregistration.component.html',
  styleUrls: ['./pfregistration.component.css']
})
export class PfregistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
