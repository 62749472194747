import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StartupRoutingModule } from './startup-routing.module';
import { ProprietorshipComponent } from './proprietorship/proprietorship.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { OnePersonCompanyComponent } from './one-person-company/one-person-company.component';
import { LimitedLiabilityPartnershipComponent } from './limited-liability-partnership/limited-liability-partnership.component';
import { PrivateLimitedCompanyComponent } from './private-limited-company/private-limited-company.component';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { UdyamRegistrationComponent } from './udyam-registration/udyam-registration.component';
import { ImportExportCodeComponent } from './import-export-code/import-export-code.component';
import { FssaiRegistrationComponent } from './fssai-registration/fssai-registration.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProprietorshipComponent,
    PartnershipComponent,
    OnePersonCompanyComponent,
    LimitedLiabilityPartnershipComponent,
    PrivateLimitedCompanyComponent,
    DigitalSignatureComponent,
    UdyamRegistrationComponent,
    ImportExportCodeComponent,
    FssaiRegistrationComponent,
  ],
  imports: [CommonModule, StartupRoutingModule, FormsModule],
})
export class StartupModule {}
