import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-limited-liability-partnership',
  templateUrl: './limited-liability-partnership.component.html',
  styleUrls: ['./limited-liability-partnership.component.css']
})
export class LimitedLiabilityPartnershipComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }

}
