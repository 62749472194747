import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-right-registration',
  templateUrl: './copy-right-registration.component.html',
  styleUrls: ['./copy-right-registration.component.css']
})
export class CopyRightRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
