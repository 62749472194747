import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LimitedLiabilityPartnershipComponent } from './service/limited-liability-partnership/limited-liability-partnership.component';
import { PrivateLimitedCompanyComponent } from './service/private-limited-company/private-limited-company.component';
import { GstRegistrationComponent } from './service/gst-registration/gst-registration.component';
import { GstComponent } from './gst/gst.component';
import { GstreturnfillingComponent } from './service/gstreturnfilling/gstreturnfilling.component';
import { GstInvoicingComponent } from './service/gst-invoicing/gst-invoicing.component';
import { ItrfillingComponent as InfillingComponent } from './service/itrfilling/itrfilling.component';
import { CheckoutComponent } from './service/checkout/checkout.component';
import { StartupModule } from './startup/startup.module';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'limited-liability-partnership',
    component: LimitedLiabilityPartnershipComponent,
  },
  {
    path: 'private-limited-company',
    component: PrivateLimitedCompanyComponent,
  },
  // { path: 'gst-registration', component: GstRegistrationComponent },
  // { path: 'gst-filling', component: GstreturnfillingComponent },
  { path: 'gst-invoicing', component: GstInvoicingComponent },
  { path: 'itr-return-filling', component: InfillingComponent },
  { path: 'checkout', component: CheckoutComponent },
  {
    path: '',
    loadChildren: () =>
      import('./income-tax/income-tax.module').then(
        (income) => income.IncomeTaxModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./startup/startup.module').then(
        (startup) => startup.StartupModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./compliance/compliance.module').then(
        (compliance) => compliance.ComplianceModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./gst-service/gst-service.module').then(
        (gst) => gst.GstServiceModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./more-services/more-services.module').then(
        (more) => more.MoreServicesModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
