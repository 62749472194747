import { Component, OnInit } from '@angular/core';
import { ApiRoutesService } from 'src/app/api-routes.service';
import { Router } from '@angular/router';

declare var Razorpay:any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor(private _service:ApiRoutesService,private router: Router) { }

  payload:any;

  ngOnInit(): void {
    this.payload = JSON.parse(localStorage.getItem('payObject')||'');
    this.initRazorPay();
    this._service.getOrderId(this.payload.amount).subscribe(res=>{
    });
  }


  // razorpay
  initRazorPay(){
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js" ;
    document.getElementsByTagName('body')[0].appendChild(script);
  }

razorPayOptions = {
  key:'rzp_test_AGK8EKstAsCisn',
  amount:0,
  currency:"INR",
  "prefill": {
    "name": "",
    "email": "",
    "contact": ""
  },
  description:'Myndax GST payment',
  order_id:'',
  handler: this.paymentCapture.bind(this),
}

pay(){
  this.razorPayOptions.amount = (+this.payload.amount)*100;
  this.razorPayOptions.prefill.name = this.payload.name;
  this.razorPayOptions.prefill.email = this.payload.email;
  this.razorPayOptions.prefill.contact = this.payload.phone;
  const rzp = new Razorpay(this.razorPayOptions);
  rzp.open();
}

handleResponse(response:any){
console.log(response)
this._service.paymentDone({
  service_id:this.payload.service_id,
  ticketStatus:3,
  client_id:this.payload.client_id,
  razorpay_payment_id:response.razorpay_payment_id,
  razorpay_signature:response.razorpay_signature,
}).subscribe(console.log)
}


paymentCapture(response: any) {
  // this.payment_id = response.razorpay_payment_id;
  // this.signature_id = response.razorpay_signature;

  // if (this.payment_id != "") {
  //   var productPlacement = { modeOfPayment: "Prepaid" };
  //   this.paymentAddData(productPlacement);
  // }
  this._service.paymentDone({
    service_id:this.payload.service_id,
    razorpay_signature:response.razorpay_signature,
    ticketStatus:3,
    client_id:this.payload.client_id,
    razorpay_payment_id:response.razorpay_payment_id,
    next_call_at_date:"",
    next_call_at_time:"",
    amount:this.payload.amount
  }).subscribe(
    res=>{
      alert("Payment Done");
      this.router.navigate(['/']);
    }
  )
}

}
