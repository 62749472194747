<app-colored-menu></app-colored-menu>
<div class="w-100 pt-2 text-grey box-sizing-border-box bg-white font-size-14 box-shadow-1 d-none d-md-block" style="position: fixed;top:50px;z-index:10001;">
    <div class="w-90 mx-auto pb-2 px-3  display-flex">
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">overview</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Types of ITR</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Penalty</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Steps of ITR</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Pricing</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Documents Required</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">FAQ</div>
        <div class="mr-3 mx-auto cursor-pointer font-size-14 hover-secondary-myntax top1s">Our Customer Satisfaction</div>
    </div>
    <div class="w-100">
        <div class="bg-secondary-myntax set-loader" style="border-radius: 10px;height: 5px;width:0%;"></div>
    </div>
</div>
<div class="set-max-100 position-relative">
    <div class="top-header"  data-menu='false' style="padding-top: 60px;" ></div>
    <div class="">
            <!--dextop view header-->
            <div class="mx-auto d-md-block d-none max-width">
                <div class="display-flex display-md- mt-5">
                    <!--main body of limited liability partner ship-->
                    <div class="w-70 position-relative">
                        <div class="text-gradient-myntax ">
                            <h1 class="font-weight-bold" style="font-size:30px">Income Tax Return Filling</h1>
                        </div>
                        <!--rating and price-->
                        <div class="w-100 display-flex">
                            <!--form start here-->
                            <div class="w-40 pr-5 box-sizing-border-box position-relative" >
                                <div class="mt-4 w-100">
                                    <form action="" method="post" #clientPhone = "ngForm" (ngSubmit) = "onSubmitPostClient(clientPhone.value)">
                                        <div class="w-100 form-myntax mb-3">
                                            <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100" ngModel required #name = "ngModel">
                                        </div>
                                        <div class="w-100 form-myntax mb-3">
                                            <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel required #phone = "ngModel">
                                        </div>
                                        <div class="w-100 form-myntax mb-3">
                                            <input type="email" name="email"  class="w-100" placeholder="Email" ngModel required #email = "ngModel">
                                        </div>
                                        <div class="text-center my-4">
                                            <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;">Book Consultancy</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -102px;right: 9rem;"></div>
                                <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
                                <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
                            </div>
                            <!--end of form is here-->
                            <!--start of quote-->
                            <div class="w-60 px-3 box-sizing-border-box">
                                <div class="w-100 display-flex">
                                    <div class="w-50">
                                        <i class="fa fa-star text-warning mr-1" style="font-size: 13px;" *ngFor="let i of [1,2,3,4,5];"></i>
                                    </div>   
                                    <div class="w-50 text-right">
                                        <span style="font-size: 14px;" class="font-weight-bold">Rs {{set_price}}</span><br/>
                                        <span class="text-grey">Inclusive all the Taxes</span>
                                    </div>
                                </div>
                                <div class="w-100 mt-3 text-grey text-capitalize" style="border-top: 1px solid lightgrey;">
                                    <div>
                                        <span class="text-grey" style="font-size: 13px;">Select Package</span><br/>
                                        <button class="mr-3 p-1 px-2 button-selected hprbtn"  (click)="price('988',basic)" >Basic</button>
                                        <button class="mr-3 p-1 px-2  button-not-selected hprbtn"  (click)="price('2899',standard)" >standard</button>
                                    </div>
                                    <div class="my-3" style="font-size: 16px;">
                                        {{set_description}}
                                    </div>
                                </div>
                            </div>
                            <!--end of quote-->
                        </div>
                        <!--end rating and price-->

                    </div>
                    <!--end of main body of unlimited liability-->
                    <div class="w-30">
                        <!--start of v2 header of the image-->
                        <div class="mx-auto w-100 setafterimage">
                            <img src="assets/img/plc.png" class="w-100" />
                        </div>
                        <!--end of v2 header of the image-->
                        <!-- <div class="mx-auto w-65 side-images">
                            <div class="image-rotater">
                                <img src="assets/img/private-limited-asset.jpg" alt="Private limited company"  class="" >
                            </div>
                        </div>
                        <div class="w-50 image-container position-relative add-side-full-square" style="height: 300px;;top:8rem">
                            <div class="grey-dot position-absolute" style="width:100px;height:100px;bottom:0px;right:-7rem"></div>
                            <div class="position-absolute" style="right: -15rem;bottom: -10rem;">
                                <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-2rem"></div>
                                <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!--end of dextop view header-->
            <!--mobile version header-->
            <div class="w-100">
                <div class=" w-100 p-2 d-md-none d-block box-sizing-border-box">
                    <!--img setting-->
                    <div class="w-100">
                        <img src="../../../assets/img/gst-registration-m.webp" width="100%" class="border-rounded" alt="">
                    </div>
                    <div class="text-gradient-myntax mt-4">
                        <h1 class="font-weight-bold" style="font-size:30px">Income Tax Return Filling</h1>
                    </div>
                    <!--description-->
                    <div class="text-justify w-100 mx-auto">
                        <div class="w-100 px-3 box-sizing-border-box">
                            <div class="w-100 display-flex">
                                <div class="w-50">
                                    <i class="fa fa-star text-warning mr-1" style="font-size: 13px;" *ngFor="let i of [1,2,3,4,5];"></i>
                                </div>   
                                <div class="w-50 text-right">
                                    <span style="font-size: 14px;" class="font-weight-bold">Rs {{set_price}}</span><br/>
                                    <span class="text-grey">Inclusive all the Taxes</span>
                                </div>
                            </div>
                            <div class="w-100 mt-3 text-grey text-capitalize" style="border-top: 1px solid lightgrey;">
                                <div>
                                    <span class="text-grey" style="font-size: 13px;">Select Package</span><br/>
                                    <button class="mr-3 p-1 px-2 button-selected hprbtn"  (click)="price('988',basic)" >Basic</button>
                                    <button class="mr-3 p-1 px-2  button-not-selected hprbtn"  (click)="price('2899',standard)" >standard</button>
                                </div>
                                <div class="my-3" style="font-size: 16px;">
                                    {{set_description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end of description-->
                    <!--forms-->
                    <div class="w-90 mx-auto box-sizing-border-box position-relative" >
                        <div class="mt-4 w-100">
                            <form action="" method="post" #clientWeb = "ngForm" (ngSubmit) = "onSubmitPostClient(clientWeb.value)">
                                <div class="w-100 form-myntax mb-3">
                                    <input type="text" name="name" style="box-sizing: border-box;" placeholder="Name" class="w-100" ngModel required #name = "ngModel">
                                </div>
                                <div class="w-100 form-myntax mb-3">
                                    <input name="phone" type="tel" class="onlyNumber" class="w-100" placeholder="Phone" ngModel required #phone = "ngModel">
                                </div>
                                <div class="w-100 form-myntax mb-3">
                                    <input type="email" name="email"  class="w-100" placeholder="Email" ngModel required #email = "ngModel">
                                </div>
                                <div class="text-center my-4">
                                    <button class="bg-secondary-myntax w-100 p-1 text-white btn" style="border-radius: 5px;border:0px;" [disabled]="spin">
                                    <span *ngIf="!spin">Book Consultancy</span>
                                        <span *ngIf="spin"><i class="fas fa-redo"></i></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -102px;right: 9rem;"></div>
                        <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: -86px;right: 6rem;z-index: 1;"></div>
                        <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -117px;right: 4rem;z-index: 1;"></div>
                    </div>
                    <!--end of forms-->
                </div>
            </div>
            <!--end of mobile version header-->
            <!--end of header tag-->
            <div class="w-100 position-relative mt-5" style="z-index: 1000;margin-top:150px !important;">
                <div class="w-md-80 w-100 mx-auto box-shadow-1 bg-myntax white-dot gst-reg-1a">
                    <div class="row">
                        <!--Total client served-->
                        <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-white text-center">Total Clients</div>
                            <div class="text-white text-center font-weight-bold">5000+</div>
                        </div>
                        <!--Total client served-->
                        <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-white text-center">Active Clients</div>
                            <div class="text-white text-center font-weight-bold">6000+</div>
                        </div>
                        <!--Total client served-->
                        <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-white text-center">Compliances Filled</div>
                            <div class="text-white text-center font-weight-bold">7000+</div>
                        </div>
                        <!--Total client served-->
                        <div class="col-md-3 col-6 p-2 box-sizing-border-box mx-auto">
                            <div class="w-80 mx-auto text-center">
                                <img src="assets/img/user.png" height="70px" />
                            </div>
                            <div class="text-center text-white">LLP Registered</div>
                            <div class="text-center text-white font-weight-bold">8000+</div>
                        </div>
                    </div>
                </div>
                <div class="mx-auto max-width my-5 py-5 top1">
                    <div class="display-md-flex display-block w-md-100 w-90 mx-auto">
                        <!--image container-->
                        <div class="w-md-50 w-100">
                            <div class="p-2  box-sizing-border-box">
                                <div class="mx-auto d-none d-md-block text-center position-relative scrollSide">
                                    <img src="assets/img/llp-registration.png" height="400px" />
                                </div>
                                <!--mobile version-->
                                 <div class="w-100 d-md-none">
                                    <img src="../../../assets/img/gst-registration.jpg" style="width:100% !important;border-radius:10px;" alt="">
                                 </div>
                                <!--end of mobile version-->
                            </div>
                        </div>
                        <!--text container-->
                        <div class="w-md-50 w-100 text-height">
                            <div class="w-100 text-gradient-myntax">
                                <h3 class="text-grey my-4 myntax-font">Itr Filling Overview </h3>
                            </div>
                            <!--llp registration -->
                            <div class="my-4 text-grey ">
                                <div class="">
                                    Its is mandatory for individuals, NRIs, partnership firms, LLPs, companies and Trust to file income tax returns each year. Individuals and NRIs are required to file income tax return, if their income exceeds Rs.2.5 lakhs per annum. Proprietorship firms and partnership firms are required income tax return - irrespective of amount of income or loss. All companies and LLPs are mandatorily required to file income tax return, irrespective of turnover or profit. IndiaFilings provides income tax efiling services with dedicated Tax Expert support. Upload your Form-16, sit back and relax. Our experts will file your income tax return and provide you the acknowledgement within 1 - 2 business days.   
                                </div>
                                <div class="text-center my-4">
                                    <button class="bg-secondary-myntax w-md-40 w-60 p-2 text-white" style="border-radius: 20px;border:0px;">Book Consultancy</button>
                                </div>
                            </div>
                            <!--end of llp registration-->
                        </div>
                    </div>
                </div>
                <!--end of about limited liability partnership-->
                <!--feature of limited liability partnership-->
                <div class="max-width mx-auto w-100 top1">
                    <div class="w-100 text-gradient-myntax">
                        <h3 class="text-grey my-4 myntax-font text-center">Types of Income Tax Return ITR </h3>
                    </div>
                    <div class="mt-3 w-md-80 w-95 mx-auto why-after position-relative">
                        <!--list-->
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 1 :</label>
                            <span class="text-grey" style="font-size: inherit;">ITR-1 form can be used by Individuals who have less than Rs.50 Lakhs of annual income earned by way of salary or pension and have one house property only.</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 2 :</label>
                            <span class="text-grey" style="font-size: inherit;">ITR-2 form must be filed by individuals who are NRIs, Directors of Companies, shareholders of private companies or having capital gains income, income from foreign sources, two or more house property, income of more than Rs.50 lakhs.</span>
                        </div>
                        <div class="grey-dot position-absolute " style="top:4rem;left:-7rem"></div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 3 : </label>
                            <span class="text-grey" style="font-size: inherit;">ITR-3 form must be filed by individuals who are professionals or persons who are operating a proprietorship business in India.</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 4 : </label>
                            <span class="text-grey" style="font-size: inherit;">ITR-4 form can be filed by taxpayers enrolled under the presumptive taxation scheme. To be enrolled for the scheme, the taxpayer must have less than Rs.2 crores of business income or less than Rs.50 lakhs of professional income. </span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 5: </label>
                            <span class="text-grey" style="font-size: inherit;">ITR-5 form must be filed by partnership firms, LLPs, associations and body of individuals to report their income and computation of tax.</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 6: </label>
                            <span class="text-grey" style="font-size: inherit;">ITR-6 form must be filed by companies registered in India..</span>
                        </div>
                        <div class="mx-auto my-4 w-100 p-4 box-sizing-border-box light-box-shadow" style="border-left:5px solid #ff914d">
                            <label class="text-myntax font-weight-bold mr-4">ITR 7: </label>
                            <span class="text-grey" style="font-size: inherit;">ITR-7 form must be filed by entities claiming exemption as charitable/religions trust, political parties, scientific research insitutions and colleges or universities.</span>
                        </div>
                        <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-3rem"></div>
                        <div class="circle-secondary position-absolute" style="width:40px;height:40px;bottom:55px;right:-1rem;z-index: 1;"></div>
                        <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem"></div>
                        <!--end of list-->
                    </div>
                </div>
                <!--end of limited liability partnership-->
                <!--benefits of limited liability partnership-->
                <div class="mx-auto max-width my-5 pt-5 top1">
                    <!--text-->
                    <div class="display-md-flex">
                        <div class="w-100 w-md-50">
                            <div class="w-100 text-gradient-myntax">
                                <h3 class="text-grey my-4 myntax-font text-center">Penalty for Late Filing Income Tax Return</h3>
                            </div>
                            <!--mobile version -->
                            <div class="w-100 d-md-none">
                                <div class="mt-5 text-center">
                                    <img src="assets/img/benefits-of-llp.png" class="w-80"/>
                                </div>
                            </div>
                            <!--end of mobile versioni-->
                            <div class="p-2 box-sizing-border-box position-relative">
                                <div class="grey-dot position-absolute" style="top:4rem;left:-8rem"></div>
                                <!--points starting from here-->
                                <div class="mt-3 w-100">
                                    <div class="text-grey">Taxpayers who do not file their income tax return on time are subject to penalty and charged an interest on the late payment of income tax. Also, the penalty for late filing income tax return on time has been increased recently. The penalty for late filing income tax return is now as follows:</div>
                                    <ul>
                                        <li>Late Filing between 1st August and 31st December - Rs.5000</li>
                                        <li>Late Filing After 31st December - Rs.10,000</li>
                                        <li>Penalty if taxable income is less than Rs.5 lakhs - Rs.1000</li>
                                    </ul>
                                </div>
                                <div class="mt-3 w-100">
                                    <div class="text-grey">
                                        <div class="font-weight-bold" style="font-size: 18px;">Income Tax Return Due Date</div>
                                        <div>The due date for income tax return filing is 31st July of every year for individual taxpayers. The due date for income tax return filing for companies and taxpayer requiring tax audit is 30th September. Section 44AD of the Income Tax Act deals with tax audit under Income Tax Act.</div>
                                    </div>
                                </div>
                                <div class="mt-3 w-100">
                                    <div class="text-grey">
                                        <div class="font-weight-bold">Business</div>  
                                        <div>In case of a business, tax audit would be required if the total sales turnover or gross receipts in the business exceeds Rs.1 crore in any previous year.</div>  
                                    </div>
                                </div>
                                <div class="mt-3 w-100">
                                    <div class="text-grey">
                                        <div class="font-weight-bold">Professional</div>  
                                        <div>In case of a profession or professional, tax audit would be required if gross receipts in the profession exceeds Rs.50 lakhs in any of the previous year.</div>  
                                    </div>
                                </div>
                                <div class="mt-3 w-100">
                                    <div class="text-grey">
                                        <div class="font-weight-bold">Presumptive Taxation Scheme</div>  
                                        <div>If a person is enrolled under the presumptive taxation scheme under section 44AD​ and total sales or turnover is more than Rs. 2 crores, then tax audit would be required.</div>  
                                    </div>
                                </div>
                                <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white" style="font-size: 15px;border:0px;border-radius: 20px;">Book Now</button></div>
                                <div class="circle-secondary position-absolute" style="width:40px;height:40px;bottom:55px;right:-1rem"></div>
                                <div class="circle-primary position-absolute" style="width:50px;height:50px;bottom:55px;right:-4rem;z-index:1;"></div>
                                <div class="circle-secondary position-absolute" style="width:30px;height:30px;bottom:-9px;right:-6rem;z-index: 1;"></div>
                                <!--end of points here-->
                            </div>
                        </div>
                        <div class="w-50 pt-5 position-relative d-md-block d-none">
                            <div class="mt-5 text-center">
                                <img src="assets/img/benefits-of-llp.png" class="w-80"/>
                            </div>
                        </div>
                    </div>
                    <!--letter-->
                </div>
                <!--end of benefits of limited liability partnership-->
                <!--steps for Registration llp-->
                <div class="w-100 my-3 position-relative top1">
                    <div class="bg-myntax w-100 overflow-hidden trans-div position-relative" style="min-height: 600px;">
                        <div class="text-center max-width mx-auto py-5">
                            <div class="text-center text-white">
                                <h4>Steps to File ITR</h4>
                            </div>
                        </div>
                        <div class="max-width mx-auto w-100" style="margin-bottom: 180px;">
                            <!--first column-->
                            <div class="row my-3 ml-5">
                                <div class="col-md-4 col-12">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/save-money.png" class="w-70 position-relative" style="top:10px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Purchase of Plan</div>
                                    <div class="text-white w-80 text-justify">
                                        Purchase one of Our plan and make the payment online using any payment method
                                    </div>
                                </div>
                                <div class="col-md-4 mt-4 mt-md-0 col-12">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/teacher.png" class="w-70 position-relative" style="top:10px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Expert Assigned</div>
                                    <div class="text-white w-80 text-justify">
                                        One expert will be assigned to you for better understanding your financial statement and other things
                                    </div>
                                </div>
                                <div class="col-md-4 mt-4 mt-md-0 col-12">
                                    <!--image container-->
                                    <div class="w-180">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/identity.png" class="w-60 position-relative" style="top:18px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Upload the Document</div>
                                    <div class="text-white w-80 text-justify">
                                        Upload the necessary document that have been asked from you and you are task is finished
                                    </div>
                                </div>
                                <!--first column-->
                                <div class="col-md-4 mt-4 mt-md-2 col-12">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/preparation.png" class="w-60 position-relative" style="top:14px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Claim Deduction</div>
                                    <div class="text-white w-80 text-justify">
                                        HRA, 80C contributions, insurance premia, donations
                                    </div>
                                </div>
                                <div class="col-md-4 mt-4 mt-md-2 col-12">
                                    <!--image container-->
                                    <div class="w-100">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/contract.png" class="w-60 position-relative" style="top:15px;left:3.5px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">Settlement</div>
                                    <div class="text-white w-80 text-justify">
                                        Pay tax for any dues or proceed to claim refunds
                                    </div>
                                </div>
                                <div class="col-md-4 mt-4 mt-md-2 col-12">
                                    <!--image container-->
                                    <div class="w-180">
                                        <div class="bg-dark-blue text-center rounded rounded-circle" style="width: 70px;height:70px;">
                                            <img src="assets/img/card.png" class="w-70 position-relative" style="top:18px" />
                                        </div>
                                    </div>
                                    <!--heading for card-->
                                    <div class="w-100 my-4 font-weight-bold text-white">File The Itr</div>
                                    <div class="text-white w-80 text-justify">
                                        Check tax refunds and enjoy your rewards
                                    </div>
                                </div>
                                <!--end of first column-->
                            </div>
                            <!--end of first column-->
                        </div>
                    </div>
                    <div class="grey-dot position-absolute " style="bottom: 4rem;left: 2rem;"></div> 
                    <div class="grey-dot position-absolute " style="top: -3rem;right: 10rem;"></div>
                    <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 9rem;"></div>
                    <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: 6rem;z-index: 1;"></div>
                    <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: 4rem;z-index: 1;"></div>
                </div>
                <!--end of steps for Registration llp-->
                <div class="mx-auto max-width w-100 top1">
                    <div class="w-100 text-gradient-myntax">
                        <h3 class="text-grey my-4 myntax-font text-center">Income Tax Return Filling Cost </h3>
                    </div>
                    <div class="my-3 w-100">
                        <div class="mx-auto w-md-80 w-95">
                            <div class="display-md-flex ml-5 mx-auto position-relative llp-cost">
                                <!--pricing-->
                                <div class="w-md-50 w-100 position-relative">
                                    <div class="w-md-70 w-100 mx-auto light-box-shadow position-relative">
                                        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 0rem;z-index:2;"></div>
                                        <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: -3rem;z-index: 1;"></div>
                                        <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: -4rem;z-index: 1;"></div>
                                        <div class="grey-dot position-absolute" style="bottom : 3rem;left: -8rem;"></div>
                                        <div class="w-100 p-4 box-sizing-border-box bg-secondary-myntax">
                                            <div class=" text-white font-weight-600 text-center" style="font-size: 22px;;">Basic</div>
                                            <div class="text-center text-white position-relative">
                                                <i class="fa fa-inr position-absolute" style="top:18px"></i><span class="font-weight-bold ml-3" style="font-size:40px">988</span>
                                                <div class="text-center text-white">All exclusive fees</div>
                                            </div>
                                        </div>
                                        <div class="p-4 box-sizing-border-box">
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Digital Signature</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">GST Registration</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Business Bank Account</div>
                                            <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white" style="font-size: 15px;border:0px;border-radius: 20px;">Book Now</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="blue-dot position-absolute background-size-300" style="top : 3rem;right: -6rem;"></div>
                                <div class="grey-dot position-absolute background-size-300" style="bottom: 3rem;right: -6rem;"></div>
                                <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 5rem;z-index:2;"></div>
                                <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: 2rem;z-index: 1;"></div>
                                <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: 1rem;z-index: 1;"></div>
                                <!--pricing start for second one-->
                                <div class="w-md-50 mt-5 mt-md-0 w-100">
                                    <div class="w-md-70 w-100 mx-auto light-box-shadow">
                                        <div class="w-100 p-4 box-sizing-border-box bg-secondary-myntax">
                                            <div class=" text-white font-weight-600 text-center" style="font-size: 22px;;">Standard</div>
                                            <div class="text-center text-white position-relative">
                                                <i class="fa fa-inr position-absolute" style="top:18px"></i><span class="font-weight-bold ml-3" style="font-size:40px">2899</span>
                                                <div class="text-center text-white">All exclusive fees</div>
                                            </div>
                                        </div>
                                        <div class="p-4 box-sizing-border-box">
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Digital Signature</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">GST Registration</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">Business Bank Account</div>
                                            <div class="text-center text-grey font-border p-2 box-sizing-border-box">6 Months GST Filling</div>
                                            <div class="mt-4 text-center"><button class="bg-myntax p-2 w-40 text-white" style="font-size: 15px;border:0px;border-radius: 20px;">Book Now</button></div>  
                                        </div>
                                    </div>
                                </div>
                                <!--end of pricing of second one-->
                                <!--end of pricing-->
                            </div>
                        </div>
                    </div>
                </div>
                <!--document required-->
                <div class="mx-auto max-width my-5 top1">
                    <div class="display-md-flex w-100">
                        <!--image container-->
                        <div class="w-50">
                            <div class="p-2 pt-5 box-sizing-border-box position-relative">
                                <div class="mx-auto d-md-block d-none text-center position-relative scrollSide">
                                    <img src="assets/img/llp-registration.png" height="400px" />
                                </div>
                                <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 5rem;z-index:2;"></div>
                                <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: 2rem;z-index: 1;"></div>
                                <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: 1rem;z-index: 1;"></div>
                                <div class="grey-dot position-absolute" style="bottom : 0rem;left: 8rem;"></div>
                            </div>
                            <!--mobile view-->
                            <div class="w-100 d-md-none">
                                <div class="w-100">
                                    <img src="assets/img/llp-registration.png" height="400px" />
                                </div>
                            </div>
                            <!--end of mobile view-->
                        </div>
                        <!--text container-->
                        <div class="w-md-50 w-90 mx-auto text-height pt-4">
                            <div class="w-100 my-5 text-gradient-myntax">
                                <h3 class="text-grey my-4 myntax-font">Benefits of Income Tax Return Filling (ITR Filling)</h3>
                            </div>
                            <!--llp registration -->
                            <div class="my-4 text-grey ">
                                <div class="">
                                    <div class="my-4 ">
                                        <div class="my-3">
                                            <div class="mx-auto text-dark font-weight-bold">Easy Loan Approval</div>
                                            <div class="mt-3">Filing the ITR will help individuals, when they have to apply for a vehicle loan (2-wheeler or 4-wheeler), House Loan etc. All major banks can ask for a copy of tax returns as a proof of income statement. This is a mandatory document for the loan approval. </div>
                                        </div>
                                        <div class="my-3">
                                            <div class="mx-auto text-dark font-weight-bold">Claim Tax Refund</div>
                                            <div class="mt-3">There can be instances when tax has been deducted (TDS) from your income even when your total taxable income is less than the basic exemption limit and you have nil tax liability for that year. In such a case, you will have to claim TDS refun for which you will have to file an Income Tax Return mandatorily.</div>
                                        </div>
                                        <div class="my-3">
                                            <div class="mx-auto text-dark font-weight-bold">Quick Visa Processing</div>
                                            <div class="mt-3">Most embassies & consultants require you to furnish copies of your tax returns for the past couple of years at the time of the visa application. These are amongst mandatorily required documents and hence it is always advisable to timely file your ITR.</div>
                                        </div>
                                        <div class="my-3">
                                            <div class="mx-auto text-dark font-weight-bold">Carry Forward Your Losses</div>
                                            <div class="mt-3">If you file the return within the original due date, you will be able to carry forward losses to subsequent years, which can be used to set off against the income of subsequent years. This means you can deduct certain losses from the relavent income which will help you reduce your tax liability of the future income. This is not possible without filing of the income tax return.  </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center my-4">
                                    <button class="bg-secondary-myntax w-md-40 w-60 mx-auto p-2 text-white" style="border-radius: 20px;border:0px;">Book Consultancy</button>
                                </div>
                            </div>
                            <!--end of llp registration-->
                        </div>
                    </div>
                </div>
                <!--end of Document Required-->
                <!--start of frequently asked question-->
                <div class="mx-auto max-width my-5 pt-5 top1">
                    <div class="w-100 text-center my-5 text-gradient-myntax">
                        <h3 class="text-grey my-4 myntax-font">Frequently Asked Question </h3>
                    </div>
                    <div class="w-80 mx-auto position-relative">
                        <!--start of frequently asked question div-->
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What is efiling of income tax return?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    E-filing or electronic filing is submitting your income tax returns online. There are two ways to file your income tax returns. The traditional way is the offline way, where you go the Income Tax Department’s office to physically file your returns. The other way is when you e-file through the internet. Over the past few years, e-filing has become popular because it is easier, doesn’t require prints of documents, and can be done for free!
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    How can you file an income tax return in India?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    You can file your income tax returns online, either on the income tax department's website or with us on www.myndtax.in.
                                    Income Tax filing or e-filling is made easy on myndtax.in. You can e-file your returns within just 7 minutes!
                                    Also note that the due date to e-file your income tax returns for the assessment year 2020-21 is on or before December 31, 2020.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    My company deducts TDS. Do I still have to file my tax return?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    Yes, deducting TDS and filing a tax return are two different things. In fact, you file a tax return to show that you've paid all the tax you needed to pay. The income tax return is also a very useful document when it comes to applying for a loan or visa.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    Should I file ITR 1 or itr2?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    You will fill ITR-2 as, when the individual earns a tax-free income that is below INR 5,000 such as agriculture income ITR-1 form is filled and if the individual earns a tax-free income, like agriculture, of more than INR 5,000 ITR-2 is filled..
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What is the difference between ITR 1 and ITR 4?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    ITR-1 is a return filing form applicable to the individual who derive income from salary, rent, and interest. ITR-4S is an income tax return form used by those assessees, who have chosen presumptive business income, and also derives their income from salary, rent, and interest.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                   What is inter-state supply ?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    Inter-state supply of goods or service is when the supply location is a different state from the delivery location. In addition, the inter-state supply applies to the supply of goods or services by an SEZ unit or the export of goods or services.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    What is ITR-V?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    ITR-V is a 1-page document that you receive after e-filing your income tax return. In case you have not e-verified your income tax return, you must print, sign and send the ITR-V to the Income Tax Department within 120 days from e-filing your tax return.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    Is it necessary to attach any documents along with the return of income?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    ITR return forms are attachment less forms and, hence, the taxpayer is not required to attach any document (like proof of investment, TDS certificates, etc.) along with the return of income (whether filed manually or filed electronically). However, these documents should be retained by the taxpayer and should be produced before the tax authorities when demanded in situations like assessment, inquiry, etc.
                                </div>
                            </div>
                        </div>
                        <div class="w-100 mb-3 light-box-shadow">
                            <!--question div-->
                            <div class="w-100 mx-auto p-3 _2faqhc cursor-pointer">
                                <i class="fa fa-angle-double-right text-grey mr-3"></i>
                                <span class="text-grey font-weight-bold" style="font-size: 14px;">
                                    If I have paid excess tax, how will it be refunded to me?
                                </span>
                                <i class="fa fa-minus text-grey float-right position-relative" style="top:5px"></i>
                            </div>
                            <!--answer div-->
                            <div class="box-sizing-border-box mx-auto w-90 text-grey _1faqhd transition-1" style="overflow: hidden;height:0px ;">
                                <div class="pb-3" style="font-size: 14px;">
                                    The excess tax paid can be claimed as refund by filing your Income-tax return. It will be refunded to you by crediting it in your bank account through ECS transfer. Don’t make mistakes in mentioning bank details such as account number, IFSC code etc in the ITR form.
                                </div>
                            </div>
                        </div>
                        <!--end of the frequently asked question-->
                        <div class="circle-secondary position-absolute" style="width: 40px;height: 40px;bottom: -2px;right: 0rem;z-index:2;"></div>
                        <div class="circle-primary position-absolute" style="width: 50px;height: 50px;bottom: 17px;right: -3rem;z-index: 1;"></div>
                        <div class="circle-secondary position-absolute" style="width: 30px;height: 30px;bottom: -2px;right: -4rem;z-index: 1;"></div>
                        <div class="grey-dot position-absolute" style="bottom : 23rem;left: -7rem;"></div>
                    </div>
                </div>
                <!--end of frequently asked question-->
                <!--Customer review-->
                <div class="w-100 max-width mx-auto py-3 top1">
                    <div class=" mb-5 font-weight-bold text-gradient-myntax text-center">
                        <h3 class="customer-satisfaction" style="font-size: 25px;">Our customer Satisfaction</h3>
                    </div> 
                    <div class="mx-auto mt-5 w-80">
                        <!-- start of customer review-->
                        <div class="w-100 display-md-flex mx-auto pt-5">
                            <!--start customer review box-->
                            <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box" style="border-radius: 20px;" >
                                <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                                    <img src="assets/img/images.jpg"/>
                                </div>
                                <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Anmol sinha</div>
                                <div class="w-100 text-center">
                                    <i class="fas fa-star mr-2 text-tertiary-myntax" *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
                                </div>
                                <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature</div>
                            </div>
                            <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box" style="border-radius: 20px;" >
                                <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                                    <img src="assets/img/images.jpg"/>
                                </div>
                                <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Anmol sinha</div>
                                <div class="w-100 text-center">
                                    <i class="fas fa-star mr-2 text-tertiary-myntax" *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
                                </div>
                                <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature</div>
                            </div>
                            <div class="w-md-35 light-box-shadow position-relative mr-md-5 px-4 pb-4 box-sizing-border-box" style="border-radius: 20px;" >
                                <div class="image-circle position-absolute" style="top:-40px;left: 40%;">
                                    <img src="assets/img/images.jpg"/>
                                </div>
                                <div class="text-dark mt-5 font-weight-bold text-center pt-5 pt-md-0" style="font-size:14px">Anmol sinha</div>
                                <div class="w-100 text-center">
                                    <i class="fas fa-star mr-2 text-tertiary-myntax" *ngFor="let dummy of ' '.repeat(5).split(''), let x = index"></i>
                                </div>
                                <div class="text-grey my-2 font-weight-bold" style="line-height: 1.5;font-size:13px">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature</div>
                            </div>
                            <!--end of customer review box-->
                        </div>
                    </div> 
                </div>
                <!--end of customer review-->
                <div class="w-100 mx-auto">
                    <svg height="100%" width="100%" id="bg-svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 76.88995215311004,177.6937799043062 153.7799043062201,155.38755980861242 251,163 C 348.2200956937799,170.61244019138758 465.7703349282298,208.14354066985646 559,224 C 652.2296650717702,239.85645933014354 721.1387559808611,234.03827751196175 811,220 C 900.8612440191389,205.96172248803825 1011.6746411483255,183.70334928229664 1120,179 C 1228.3253588516745,174.29665071770336 1334.1626794258373,187.14832535885168 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150"></path><defs><linearGradient id="gradient"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 97.01435406698562,410.2200956937799 194.02870813397124,420.4401913875598 293,400 C 391.97129186602876,379.5598086124402 492.8995215311005,328.4593301435407 600,326 C 707.1004784688995,323.5406698564593 820.3732057416267,369.72248803827756 911,398 C 1001.6267942583733,426.27751196172244 1069.6076555023924,436.6507177033493 1154,434 C 1238.3923444976076,431.3492822966507 1339.1961722488038,415.67464114832535 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150"></path></svg>
                </div>
            </div>
    </div>
</div>
