import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provisional-patent',
  templateUrl: './provisional-patent.component.html',
  styleUrls: ['./provisional-patent.component.css']
})
export class ProvisionalPatentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
