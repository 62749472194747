<div class="w-100 bg-myntax menu-header" style="z-index: 100000;">
    <div class="w-100 max-width mx-auto d-none d-md-flex">
        <div class="w-25">
            <a href="/"><img src="assets/img/myntax-transparent.png" class="mt-1 menu-logo" height="40px" /></a>
        </div>
        <div class="w-65">
            <ul class="m-0" style="position:relative">
                    <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
                        <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Startup</a>
                        <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000;width:640px">
                        <div class="row">
                            <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Popular</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Proprietorship</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Partnership</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">One Person Company</a></li>
                                    <li><a href="/limited-liability-partnership" class="text-dark text-decoration-none hover-bold">Limited Liability Partnership</a></li>
                                    <li><a href="/private-limited-company" class="text-dark text-decoration-none hover-bold">Private limited Company</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Public Company</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Public Limited Company</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Nidhi Company</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Producer Company</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Section 8 company</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">MSME registration</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Other Registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Digital Signature</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Udyog Aadhaar</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Import Export Code</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">FSSAI registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Trade License</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li> 
                    <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
                        <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Intellectual Property</a>
                        <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000; width:500px">
                        <div class="row">
                            <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Popular</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Trademark Registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Trademark Objection</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Trademark Opposition</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Trademark Renewal</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">OTHER</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Copyright Registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Design Registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Provisional Patent</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Patent Registration</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li> 
                    <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
                        <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">GST</a>
                        <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000;width:500px">
                        <div class="row">
                            <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a class="text-dark text-decoration-none font-weight-bold">GST SERVICE</a></li>
                                    <li><a href="gst-registration" class="text-dark text-decoration-none hover-bold">GST Registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">GST Invoicing</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">GST Annual Return </a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">GST LUT filling</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Temporary GST number</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-6 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">GST SERVICE</a></li>
                                    <li><a href="gst-filling" class="text-dark text-decoration-none hover-bold">GST Return Filling</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">e way Bill</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Input Tax Credit</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li> 
                    <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
                        <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Income Tax</a>
                        <div class="dropdown-l bg-white box-shadow-1 transition-1" style="z-index: 1000;width:250px">
                        <div class="row">
                            <div class="col-lg-12 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Annual Compliance </a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Proprietorship IT</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Private Limited company IT</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">LLP IT</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">ITR Filling</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Income Tax notice</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">TDS Return Filling</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Form 16 Issuance</a></li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </li> 
                    <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
                        <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">Compliance</a>
                        <div class="dropdown bg-white box-shadow-1 transition-1" style="z-index: 1000; width:700px">
                        <div class="row">
                            <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Payroll  </a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Payroll</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">PF Registration</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">PF Return Filling</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">ESI Registration</a></li>
                                </ul>
                            </div> 
                            <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Corporate Fillings </a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Registered Office Change</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Add Directors</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Remove Directors</a></li>
                                </ul>
                            </div> 
                            <div class="col-lg-4 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">Others </a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Share Transfer</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Add Directors</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Remove Directors</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Increase Authorized Capital</a></li>
                                    
                                </ul>
                            </div> 
                        </div>
                    </div>
                </li>  
                <li class="menu1 cursor-pointer text-white m-0 p-3 box-sizing-border-box">
                    <a href="#" class="hover-tertiary-myntax hover-show menu-header-tab text-white">More</a>
                    <div class="dropdown-l bg-white box-shadow-1 transition-1" style="z-index: 1000;width:250px">
                        <div class="row">
                            <div class="col-lg-12 p-2 box-sizing-border-box menu-data-list">
                                <ul>
                                    <li><a href="" class="text-dark text-decoration-none font-weight-bold">About Us</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Contact Us</a></li>
                                    <li><a href="#" class="text-dark text-decoration-none hover-bold">Documents</a></li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </li> 
            </ul>
        </div>
        <div class="w-20 text-white text-right position-relative">
            <span class="text-white m-0 p-3 d-inline-block box-sizing-border-box hover-bold cursor-pointer float-right">Login</span> <span class="text-white m-0 hover-bold p-3 d-inline-block box-sizing-border-box cursor-pointer  float-right">Signup</span>
        </div>
    </div>
    <div class="w-100 mx-auto bg-myntax py-2 display-flex d-md-none">
        <div class="w-50">
            <div class="w-100 px-2">
                <a href="/"><img src="../../assets/img/myntax-transparent.png" class="mt-1 menu-logo" height="30px" /></a>
            </div>
        </div>
        <div class="w-50 pr-3 box-sizing-border-box pt-2 text-right">
            <i class=" text-white fa fa-bars" (click)="menuShow()" style="font-size:25px;"></i>
        </div>
    </div>
</div>
<div class="w-100 position-fixed d-none  mobile-show mobileChecking" style="background-color:rgba(0,0,0,0.4);height: 100vh;top:0px;z-index: 10000;">
    <div class="display-flex">
        <div class="w-25" (click)="crossSide()">  
        </div>
        <div class="w-75">
            <div class="w-100 bg-white p-3 box-sizing-border-box h-100" style="min-height: 100vh !important;">
                <div class="w-100">
                    <!--img-->
                    <div class="w-100">
                        <img src="../../assets/img/myntax.png" alt="myntax logo" style="height:30px;">
                        <i class="fa fa-times text-dark font-weight-bold float-right position-relative cross" (click)="crossSide()" style="font-size:18px;top:8px"></i>
                    </div>
                </div>
                <div class="w-100 border-top-1 py-3 mt-4">
                    <div><a href="/" class="text-dark px-3  p-1 home-pills">Home</a></div>
                </div>
                <div class="w-100 border-top-1 py-2">
                    <div (click)="down()" class="parent">
                        <a class="text-dark px-3 font-weight-bold p-1">Startup</a>
                        <i class="fa fa-sort-desc float-right font-weight-bold"></i>
                    </div>
                    <div class="outerDownShow d-none">
                        <div class="pl-3 mt-2 border-top-1">
                            <div class="py-2"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Proprietorship</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Partnership</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Limited liability partnership</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Private limited company</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">One Person Company</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Nidhi Company</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Producer Company</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Section 8 Company</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Msme Registration</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Fssai Registration</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Digital Signature</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Udyog Aadhaar</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Digital Signature</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Export import Code</a></div>
                            <div class="py-2 border-top-1"><a href="" class="text-dark px-3 p-2 startup-proprietorship">Trade Licence</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>